import { ThemeColorSet } from "@/lib/colors";
import { NonProfit } from "@/models/non_profits";
import Link from "next/link";

interface Props {
    non_profit: NonProfit,
    colors: ThemeColorSet
}

export default function SupportLocalFeatured({ colors, non_profit, ...props }: Props) {
    
    const website = non_profit.website.replace(/(^\w+:|^)\/\//, '');

    return (
        <div className="nonprofit-connect flex space-x-2 md:space-x-4 text-base md:text-xl">
            {non_profit.website && <Link href={non_profit.website} className={`px-2 md:px-4 h-9 md:h-12 flex shrink-0 items-center bg-${colors.accent} text-${colors.accent_text} rounded-md`} target="_blank">{website}</Link>}
            {non_profit.facebook && <Link href={non_profit.facebook} className={`w-9 h-9 md:w-12 md:h-12 text-base md:text-2xl flex shrink-0 items-center justify-center bg-${colors.accent} text-${colors.accent_text} rounded-full`} target="_blank" aria-label="Facebook"><i className="fa-brands fa-facebook" aria-hidden={true}></i></Link>}
            {non_profit.instagram && <Link href={non_profit.instagram} className={`w-9 h-9 md:w-12 md:h-12 text-base md:text-2xl flex shrink-0 items-center justify-center bg-${colors.accent} text-${colors.accent_text} rounded-full`} target="_blank" aria-label="Instagram"><i className="fa-brands fa-instagram" aria-hidden={true}></i></Link>}
        </div>
    );
}