'use client';

import './scrolling.css';

import Heading, { HeadingProps } from "./basic";
import { useEffect, useRef, useState } from 'react';

interface ScrollingProps extends HeadingProps {
    speed?: "slow" | "medium" | "fast" | number,
    forward?: boolean,
}

export default function ScrollingHeading({ speed = "medium", size = "14xl", forward = false, children, ...props }: ScrollingProps) {

    const container = useRef<HTMLDivElement>(null);
    const headings = useRef<HTMLDivElement>(null);

    const style = { "--speed": `${getSpeed(speed)}` } as React.CSSProperties;
    
    const [initialized, setInitialized] = useState(false);
    const [instances, setInstances] = useState(0);

    useEffect(() => {

        const updateInstances = () => {

            if (!container.current || !headings.current || !headings.current.children[0]) return;
            
            const containerWidth = window.innerWidth;
            
            const heading = headings.current.children[0];
            const headingReact = heading.getBoundingClientRect();

            let numInstances;
            
            numInstances = Math.ceil((containerWidth / headingReact.width) * 1.5);

            numInstances = Math.max(numInstances, 5);

        
            setInstances(numInstances);
            setInitialized(true);
        };

        window.addEventListener('resize', updateInstances);

        updateInstances();

        return () => {
            window.removeEventListener('resize', updateInstances);
        };
    }, [container])

    return (
        <div ref={container} className={`scrolling-text ${forward ? 'forward' : 'reverse'}` } style={style}>
            <div ref={headings} className='scrolling-text-inner'>
                <>
                    {   !initialized &&
                        <Heading as='span' whiteSpace={null} size={size} {...props} style={{ visibility: "hidden"}}>{children}<span>&nbsp;</span></Heading>
                    }
                    {
                        
                        initialized && [...Array(instances)].map((i, index) => <Heading as='span' key={index} whiteSpace={null} size={size} {...props}>{children}<span>&nbsp;</span></Heading>)
                    }
                </>
            </div>
        </div>
    )
}

function getSpeed(speed: string | number) {
    switch (speed) {
        case "slow":
            return "14s";
        case "medium":
            return "7s";
        case "fast":
            return "4s";
        default:
            return `${speed}s`
    }

}