'use client';

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { SlowMo } from "gsap/EasePack";
import moment from 'moment';
import { useState } from "react";

import Text from "@/components/core/text";

import Cal, { getCalApi } from "@calcom/embed-react";
import { useEffect } from "react";
import Form, { FormData, handleErrorResponse } from "@/modules/form";
import { Button } from "@/components/core/button";

import dynamic from "next/dynamic";
import { getClient } from "@/lib/rest";
import { getFormUrl } from "@/lib/cms";

import { BookingSuccessfulEvent, BookingSuccessfulData, CALENDAR_THEME } from "@/models/cal";
import { Tab, TabPanel, Tabs } from "@/components/core/tabs";

const Select = dynamic(() => import("react-select"), { ssr: false });

interface Props {
    compact?: boolean,
    form: FormData,
    start_call: string,
    className?: string,
}

export default function StartFormWrapper(props: Props) {

    const {
        compact = false,
    } = props;

    const [formData, setFormData] = useState<{ [key: string]: any }>({});
    const [formErrors, setFormErrors] = useState<{ [key: string]: any }>({});
    const [bookingData, setBookingData] = useState<BookingSuccessfulData |null>(null);

    const [step, setStep] = useState(1);
    const [enableScheduling, setEnableScheduling] = useState(true);
    const [disableSubmit, setDisableSubmit] = useState(false);

    const [validated, setValidated] = useState(false);
    const [scheduled, setScheduled] = useState(false);
    const [completed, setCompleted] = useState(false);
    const [processing, setProcessing] = useState(false);

    const optionsHasSchedule = [
        { value: false, label: "No" },
        { value: true, label: "Yes" }
    ]

    useEffect(() => {
        (async function () {

            const Cal = await getCalApi();

            Cal("ui", { 
                "theme": "light",
                "hideEventTypeDetails": false,
                "layout": "month_view",
                "cssVarsPerTheme": CALENDAR_THEME
            });

            Cal("on", {
                action: "linkFailed",
                callback: (e) => {
                    const { data, type, namespace } = e.detail;
                    console.error(data);
                }
            });

            Cal("on", {
                action: "linkReady",
                callback: (e) => {
                    const { data, type, namespace } = e.detail;
                }
            });

            Cal("on", {
                action: "bookingSuccessful",
                callback: (e: any) => {
                    const { data: eventData, namespace } = e.detail as BookingSuccessfulEvent;

                    setBookingData(eventData);
                    
                    setFormData((formData) => {
                        return { ...formData, appointment: eventData.booking.uid };
                    });
                    setScheduled(true);
                }
            });
        })();
    }, []);

    const previous = () => {
        setStep(1)
    }

    const next = () => {
        setStep(2);
    }

    const submit = () => {
        setProcessing(true);
        setDisableSubmit(true);
        setFormErrors({});

        getClient()
            .post(`${getFormUrl(props.form)}`, formData)
            .then((response) => {
                setDisableSubmit(false);
                setCompleted(true);
                setStep(3);
                setProcessing(false);
            })
            .catch(async (errorOrResponse: Error | Response) => {
                const errors = await handleErrorResponse(errorOrResponse);

                setDisableSubmit(false);
                setFormErrors(errors);
                setProcessing(false);
            })
    }

    const onFormChange = (values: { [key: string]: any}, validated: boolean) => {
        setFormData(values);
        setValidated(validated);
    }

    const onSchedulableChange = (selectedOption: any) => {
        const option = (selectedOption as { value: boolean, label: string }).value;
        setEnableScheduling(option);
    }

    return (
        <div className={`form-wrapper w-full ${compact ? 'form-wrapper-compact' : ''} ${props.className ?? ''}`}>
            <Tabs className="form-tabs" >
                <Tab id="panelInformation" selected={step == 1} disabled={completed} onClick={(e) => setStep(1)}>01 Information</Tab>
                <Tab id="panelScheduling" selected={step == 2} disabled={!validated || completed} onClick={(e) => setStep(2)}>02 Schedule</Tab>
                <Tab id="panelConfirmation" selected={step == 3} disabled={!completed}>03 Confirmation</Tab>
            </Tabs>
            <TabPanel id="panelInformation" className="space-y-4" selected={step == 1}>
                <Form form={props.form} data={formData} errors={formErrors} onChange={onFormChange} />
                <div className="flex justify-end w-full">
                    <Button label="Next" disabled={!validated} onClick={next} />
                </div>
            </TabPanel>
            <TabPanel id="panelScheduling" className="space-y-4" selected={step == 2}>
                {!scheduled && <div>
                    <Text as="label" family="display" size="xl" weight="bold" content="Would you like to schedule a call with us?" />
                    <Select
                        className="form-input form-input-select"
                        options={optionsHasSchedule}
                        defaultValue={optionsHasSchedule[1]}
                        onChange={onSchedulableChange} />
                </div>}
                {enableScheduling && !scheduled && <Cal
                    calOrigin={process.env.NODE_ENV === 'development' ? 'https://cal.dev' : 'https://cal.com'}
                    calLink={process.env.NODE_ENV === 'development' ? '328studios/test' : props.start_call}
                    config={{
                        name: `${formData.first_name} ${formData.last_name}`,
                        email: formData.email,
                    }} />}
                {enableScheduling && bookingData && <Text size="2xl">
                    <strong>{bookingData.booking.title}</strong> has been scheduled for <strong>{moment(bookingData.date).format('LLLL')}</strong>
                </Text>}
                <div className="flex justify-end w-full">
                    <Button label={processing ? "Submitting" : "Submit"} disabled={(enableScheduling && !scheduled) || disableSubmit} onClick={submit} />
                </div>
            </TabPanel>
            <TabPanel id="panelConfirmation" className="space-y-4" selected={step == 3}>
                <Text size="2xl" content="Thanks! We have recieved your information." />
            </TabPanel>
        </div>
    )
}

