'use client';

import { useCursor } from "@/modules/cursor/provider";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { CSSProperties, ForwardedRef, forwardRef, ReactNode, useRef, useState } from 'react';

gsap.registerPlugin(ScrollTrigger);

interface Props {
    width?: "container" | "xs" | "sm" | "md" | "lg" | "xl" | "2xl" | "3xl" | "4xl" | "5xl" | "6xl" | "7xl" | "fluid",
    height?: string,
    center?: boolean,
    className?: string,
    classes?: string | null,
    style?: CSSProperties,
    children?: ReactNode,
}

const Container = forwardRef(function Container(props: Props, ref: ForwardedRef<HTMLDivElement>) {
    const {
        height,
        width = "container",
        center = false,
        className = "",
        classes = "",
        style,
    } = props;

    var combinedClasses = "";

    if (className) {
        combinedClasses += className;
    }

    if (classes) {
        combinedClasses += classes;
    }

    const widths = {
        container: "",
        xs: "max-w-xs",
        sm: "max-w-sm",
        md: "max-w-md",
        lg: "max-w-lg",
        xl: "max-w-xl",
        "2xl": "max-w-2xl",
        "3xl": "max-w-3xl",
        "4xl": "max-w-4xl",
        "5xl": "max-w-5xl",
        "6xl": "max-w-6xl",
        "7xl": "max-w-7xl",
        "fluid": ""
    }
    
    return (
        <div ref={ref} className={`mx-auto ${center ? 'flex flex-col items-center justify-center' : ''}  ${combinedClasses} ${height ? `h-${height}` : ''} ${width == "fluid" ? "container-fluid" : `container ${widths[width]}`}`} style={style}>
            {props.children}
        </div>
    )
});

export default Container;