'use client';

import React, { CSSProperties, RefObject, useEffect, useRef } from "react";
import { useMagnetize } from "../../../hooks/useMagnetize";
import { colorToHex, useThemeColors } from "@/lib/colors";
import "./button.css";
import Link from "next/link";

export interface ButtonProps {
    className?: string,
    label: string,
    theme_color?: string,
    background_color?: string,
    text_color?: string,
    href?: string,
    target?: "_blank" | "_self"
    attributes?: {
        [key: string]: string
    }
    onClick?: () => void,
    block?: boolean,
    magnetic?: boolean,
    disabled?: boolean,
    size?: "xs" | "sm" | "base" | "lg" | "xl" | "2xl";
}

const ButtonSizes = {
    "xs": "px-4 py-2 text-lg",
    "sm": "px-6 py-4 text-xl",
    "base": "px-8 py-6 text-2xl",
    "lg": "px-8 py-6 text-3xl",
    "xl": "px-10 py-8 text-4xl",
    "2xl": "px-10 py-8 text-5xl",
}

export default function Button({ theme_color = "primary", block = false, size = "base", ...props }: ButtonProps) {
    
    const button = useRef<HTMLAnchorElement | HTMLButtonElement>();
    const setRef: React.RefCallback<HTMLAnchorElement | HTMLButtonElement> = (node) => {
        if (node !== null) {
            button.current = node
        }
    };
    
    const colors = useThemeColors(theme_color ?? "primary");

    const style = {
        "--background": colorToHex(props.background_color ?? colors.accent),
        "--color": colorToHex(props.background_color ?? colors.accent_text),
        "--hover-background": colorToHex(props.background_color ?? colors.text),
        "--hover-color": colorToHex(props.text_color ?? colors.background),
    } as CSSProperties;

    useMagnetize(button);

    const componentProps = {
        className: `button shrink-0 relative rounded-lg text-center font-bold leading-none ${block ? 'block' : 'block md:inline-block'} ${props.className ?? ''} ${ButtonSizes[size]}`,
        style: style,
        attributes: props.attributes
    }

    return (
        <>
            {
                props.href && <Link ref={setRef} href={props.href} target={props.target ?? undefined}  {...componentProps}>
                    {props.label}
                </Link>
            }
            {
                !props.href && <button ref={setRef} onClick={props.onClick} disabled={props.disabled} {...componentProps}>
                    {props.label}
                </button>
            }
        </>
    )
    
};