'use client';

import React, { useState, useEffect, useContext, createContext, ReactNode } from 'react';
import { colorToHex } from '@/lib/colors';

export interface CursorEffects {
  color?: null | string,
  textColor?: null | string,
  text?: null | string,
};

interface ThemeColor { 
  hex: string, 
  rgb: number[], 
  cursor: string
};

const themeColors: ThemeColor[] = [
  {
    hex: "#00A6ED",
    rgb: [0, 166, 224],
    cursor: "#F7FF07"
  },
  {
    hex: "#F7FF07",
    rgb: [247, 255, 7],
    cursor: "#3E3E3D"
  },
  {
    hex: "#3E3E3D",
    rgb: [62, 62, 61],
    cursor: "#F7FF07"
  },
  {
    hex: "#ffffff",
    rgb: [255, 255, 255],
    cursor: "#00A6ED"
  }
];

interface CursorContextInterface {
  expanded: boolean,
  text: string | null,
  textColor: string | null,
  color: string | null,
  expandCursor: (text?: string | null, color?: string | null, textColor?: string | null) => void,
  afterMinimizeCursor: () => void,
  minimizeCursor: (color?: string | null) => void,
  changeCursorColor: (color?: string | null) => void,
}

const defaultState = {
  expanded: false,
  text: null,
  textColor: null,
  color: null,
  expandCursor: (text?: string | null, color?: string | null, textColor?: string | null) => {
  },
  minimizeCursor: (color?: string | null) => {
  },
  afterMinimizeCursor: () => {
  },
  changeCursorColor: (color?: string | null) => {
  },
};

const CursorContext = createContext<CursorContextInterface>(defaultState);

interface CursorProviderInterface {
  children: ReactNode,
}

export function CursorProvider({ children }: CursorProviderInterface) {
  const cursor = useProvideCursor();
  return <CursorContext.Provider value={cursor}>{children}</CursorContext.Provider>;
}

export const useCursor = () => {
  return useContext(CursorContext);
};

function useProvideCursor(): CursorContextInterface {
  const [expanded, setExpanded] = useState<boolean>(false);
  const [color, setColor] = useState<string|null>(null);
  const [text, setText] = useState<string | null>(null);
  const [textColor, setTextColor] = useState<string | null>(null);

  var tempColor: string | null = null;
  var tempTextColor: string | null = null;

  const changeCursorColor = (color?: string | null) => {
    if (typeof color !== "undefined") {
      color = colorToHex(color);
      setColor(color);
    } else {
      setColor(null);
    }
  };

  const expandCursor = (text?: string | null, color?: string | null, textColor?: string | null) => {
    setExpanded(true);

    if (typeof text !== "undefined") {
      setText(text);
    } else {
      setText(null);
    }

    if (typeof color !== "undefined") {
      color = colorToHex(color);
      setColor(color);
      setTextColor(color);
    } else {
      setColor(null);
      setTextColor(null);
    }

    // if (typeof textColor !== "undefined") {
    //   textColor = colorToHex(textColor);
    //   setTextColor(textColor);
    // } else {
    //   setTextColor(null);
    // }
  };

  const minimizeCursor = (color?: string | null, textColor?: string | null) => {
    setExpanded(false);
   
    if (typeof color !== "undefined") {
      color = colorToHex(color);
      setColor(color);
    } else {
      setColor(null);
    }

    if (typeof textColor !== "undefined") {
      tempTextColor = colorToHex(textColor);
    } else {
      tempTextColor = null;
    }
  };

  const afterMinimizeCursor = () => {
    setText(null);
    setColor(tempColor);
    setTextColor(tempTextColor);
  };

  return {
    expanded,
    text,
    textColor,
    color,
    expandCursor,
    minimizeCursor,
    afterMinimizeCursor,
    changeCursorColor,
  };
}

// const getContrastRatio = (color1: number[], color2: number[]) => {
//   // Calculate the contrast ratio between two colors
//   const luminance1 = color1.reduce((acc, val) => {
//     const channel = val / 255;
//     return channel <= 0.03928 ? acc + (channel / 12.92) : acc + Math.pow((channel + 0.055) / 1.055, 2.4);
//   }, 0);

//   const luminance2 = color2.reduce((acc, val) => {
//     const channel = val / 255;
//     return channel <= 0.03928 ? acc + (channel / 12.92) : acc + Math.pow((channel + 0.055) / 1.055, 2.4);
//   }, 0);

//   const contrastRatio = (luminance1 + 0.05) / (luminance2 + 0.05);
//   return contrastRatio;
// }

// const calculateColorDistance = (color1: number[], color2: number[]) => {
//   // Calculate the Euclidean distance between two RGB colors
//   const squaredDistance = color1.reduce((acc, value, index) => {
//     return acc + Math.pow(value - color2[index], 2);
//   }, 0);

//   return Math.sqrt(squaredDistance);
// }

// const findContrastColor = (baseColor: number[]) => {

//   let maxContrastColor = '';
//   let maxContrastRatio = 0;

//   // Iterate through the array of colors and find the one with the maximum contrast
//   themeColors.forEach(themeColor => {
//     const contrastRatio = getContrastRatio(baseColor, themeColor.rgb);

//     if (contrastRatio > maxContrastRatio) {
//       maxContrastRatio = contrastRatio;
//       maxContrastColor = themeColor.hex;
//     }
//   });

//   return maxContrastColor;
// }

  // useEffect(() => {
  //   const container = document.createElement("div");
  //   // container.style.width = "20px";
  //   // container.style.height = "20px";
  //   container.style.position = "fixed";
  //   container.style.top = "16px";
  //   container.style.right = "16px";
  //   container.style.zIndex = "999";
  //   container.style.border = "#000000 solid 5px";
  //   document.body.appendChild(container);

  //   const canvas = document.getElementById('cursorColor') as HTMLCanvasElement;
  //   const onMouseMove = (e: MouseEvent) => {

  //     const x = e.pageX;
  //     const y = e.pageY;

  //     getColor(x, y);
  //   }

  //   const throttledOnMouseMove = _.throttle(onMouseMove, 100);

  //   const getColor = (x: number, y:number) => {

  //     html2canvas(
  //       document.body,
  //       { 
  //         //canvas: clonedCanvas,
  //         x,
  //         y,
  //         width: 1,
  //         height: 1,
  //         ignoreElements: (element) => element.classList.contains('cursor')
  //       },
  //     ).then(function (canvas) {
  //       // Get the color data at the mouse coordinates
  //       const context = canvas.getContext('2d', { willReadFrequently: true });

  //       if (context) {
  //         const pixel = context.getImageData(0, 0, 1, 1).data;

  //         // Extract RGB values
  //         const red = pixel[0];
  //         const green = pixel[1];
  //         const blue = pixel[2];
  //         const rgb = [red, green, blue];

  //         const cursorColor = getCursorColor(rgb);

  //         // Display the RGB values in the console
  //         console.log(`Color under mouse: RGB(${red}, ${green}, ${blue}),  contrasting color is ${cursorColor}`);
  //         setColor(cursorColor);
  //       }
  //     });
  //   }

  //   const getCursorColor = (pointColor: number[]) => {
  //     const closestColor = themeColors.reduce((closest: { color: ThemeColor | null , distance: number }, currentColor) => {
          
  //         const distance = calculateColorDistance(pointColor, currentColor.rgb);

  //         if (distance < closest.distance) {
  //           return { color: currentColor, distance: distance };
  //         }

  //         return closest;
  //       }, { color: null, distance: Infinity });

  //     return closestColor.color ? closestColor.color.cursor : findContrastColor(pointColor);
  //   }
    

  //   window.addEventListener("mousemove", throttledOnMouseMove);

  //   return () => {
  //     window.removeEventListener("mousemove", throttledOnMouseMove);
  //   }
  // }, []);