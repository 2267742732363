'use client';

import ScrollingHeading from "../core/heading/scrolling";
import { useThemeColors } from "@/lib/colors";
import { useCursorEffects } from "@/modules/cursor/useCursorEffects";
import { useRef } from "react";
import Container from "../core/container";
import Section from "../core/section";


interface Props {
  title: string | null,
  title_size: "default" | "xs" | "sm" | "base" | "lg" | "xl" | "2xl" | "3xl" | "4xl" | "5xl" | "6xl" | "7xl" | "8xl" | "9xl" | "10xl" | "11xl" | "12xl" | "13xl" | "14xl" | "15xl" | "16xl" | "17xl" | "18xl" | "19xl" | "20xl" | "title",
  speed: "slow" | "medium" | "fast",
  background_color: string,
  forward: boolean,
}

export default function ScrollingTextContent({ title, ...props }: Props) {
  
  const container = useRef<HTMLDivElement>(null);
  
  const colors = useThemeColors(props.background_color);
  
  useCursorEffects(container, { color: colors.cursor });

  return (
    <Section ref={container} background_color={props.background_color}>
      <Container width="fluid" center={true} className="py-8">
        <ScrollingHeading color={colors.text} transform="uppercase" size={props.title_size} {...props}>{title}</ScrollingHeading>
      </Container>
    </Section>
  )
}