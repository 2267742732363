'use client';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect, useRef } from "react";
import Container from "../core/container";
import Section from "../core/section";
import { Heading, StylizedHeading } from "../core/heading";
import { useGSAP } from "@gsap/react";

gsap.registerPlugin(ScrollTrigger);

interface Props {
    page_title: string,
    title: string,
    subtitle?: string,
}

export default function PageHero(props: Props) {

    const tl = useRef<gsap.core.Timeline>();
    const container = useRef<HTMLDivElement>(null);

    useGSAP(() => {
        tl.current = gsap.timeline();

        gsap.set(container.current!.children, { autoAlpha: 0, y: 200 });

        tl.current
            .to(container.current!.children, { autoAlpha: 1, y: 0, stagger: 0.1 });

    }, { scope: container, dependencies: [] });

    const themes = ["primary", "secondary", "tertiary"];

    return (
        <Section>
            <Container ref={container} className="flex flex-col justify-center py-32 space-y-4">
                <>
                    <StylizedHeading as="h1" content={props.page_title}/>
                    <div className="max-w-screen-lg pr-2 sm:pr-0">
                        <Heading size="10xl" transform="uppercase" content={props.title} />
                    </div>
                    {props.subtitle &&
                        <Heading size="5xl" weight="normal" content={props.subtitle} className="text-5xl"/>
                    }
                </>
            </Container>
        </Section>
    );
}