'use client';

import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { SlowMo } from "gsap/EasePack";
import { useRef, useState, useEffect } from "react";

import Container from "../../../components/core/container";
import Section from "../../../components/core/section";

import { gql, useQuery } from "@apollo/client";
import TestimonialCard from "./card";
import { useThemeColors, colorToHex } from "@/lib/colors";
import { Testimonials } from "@/models/testimonials";

// @ts-ignore
import { Splide, SplideTrack, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import '../style.css';
import { Heading } from "@/components/core/heading";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(SlowMo);

interface Props {
    title?: string,
    order_by: "recent" | "random",
    background_color: string,
    data: Testimonials
}

export default function TestimonialsList({ data, background_color, ...props}: Props) {

    const [mounted, setMounted] = useState<boolean>(false);

    const container = useRef<HTMLDivElement>(null);

    const title = useRef<HTMLHeadingElement>(null);

    const tl = useRef<gsap.core.Timeline>();

    const colors = useThemeColors(background_color);

    const style = { 
        "--text-color": colorToHex(colors.text),
        "--accent-color": colorToHex(colors.accent),
        "--accent-text-color": colorToHex(colors.accent_text)
     } as React.CSSProperties;

    useGSAP(() => {
        if (mounted) {
            tl.current = gsap.timeline({
                scrollTrigger: {
                    trigger: container.current,
                    start: "top 75%"
                }
            });

            //gsap.set(title.current, { autoAlpha: 0, y: 200 });
            gsap.set("li.splide__slide", { autoAlpha: 0, y: 200 });

            tl.current
                //.to(title.current, { autoAlpha: 1, y: 0 })
                .to("li.splide__slide", { autoAlpha: 1, y: 0, stagger: .1 });
        }

    }, { scope: container, dependencies: [mounted] });

    return (
        <Section ref={container} background_color={background_color} className={`space-y-16 py-32`} style={style}>
            <Container>
                <Heading ref={title} as="h2" size="10xl" align="center" color={colors.text} transform="uppercase" content={props.title ?? "Let The People Speak"} />
            </Container>
            <Container width="fluid">
                <Splide hasTrack={false} className="testimonials"
                    onMounted={() => setMounted(true)} 
                    options={{
                        type: 'loop',
                        drag: 'free',
                        snap: true,
                        focus: "center",
                        autoWidth: true,
                    }}>
                    <SplideTrack>
                        <>
                            {data.collections?.testimonials.map((testimonial, index) => {
                                return (
                                    <SplideSlide key={testimonial.id} className="w-full max-w-[600px]">
                                        <TestimonialCard testimonial={testimonial} colors={colors} />
                                    </SplideSlide>

                                )
                            })}
                        </>
                    </SplideTrack>
                    <div className="splide__arrows">
                        <button className="splide__arrow splide__arrow--prev">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="3" stroke="currentColor" className="w-12">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                            </svg>
                        </button>
                        <button className="splide__arrow splide__arrow--next">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="3" stroke="currentColor" className="w-12">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                            </svg>
                        </button>
                    </div>
                </Splide>
            </Container>
        </Section>
    )
}

const GET_TESTIMONIALS = gql`
query Testimonials($sort: String = "updated_at desc") {
   	collections: entries( collection: "testimonials", sort: [$sort]) {
    	... on EntryInterfacePagination {
            testimonials: data {
                id,
                title,
                ... on Entry_Testimonials_Testimonial {
                    company,
                    content
                }
            }
        }
    }
}
`;