'use client';

import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { SlowMo } from "gsap/EasePack";
import { useRef } from "react";

import Container from "../../../components/core/container";
import Section from "../../../components/core/section";

import { gql } from "@apollo/client";
import { StylizedHeading } from "@/components/core/heading";
import InitiativeTile from "./tile";
import InitiativeCard from "./card";
import { useThemeColors } from "@/lib/colors";
import { Initiatives } from "@/models/initiatives";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(SlowMo);

interface Props {
    title: string,
    display: "list" | "grid",
    background_color: string,
    data: Initiatives
}


export default function InitiativeList({ data, background_color, display = "grid", ...props}: Props) {

    const container = useRef<HTMLDivElement>(null);
    const title = useRef<HTMLHeadingElement>(null);

    const items = useRef<HTMLDivElement>(null);

    const tl = useRef<gsap.core.Timeline>();

    const colors = useThemeColors(background_color);

    useGSAP(() => {
        tl.current = gsap.timeline({
            scrollTrigger: {
                trigger: container.current,
                start: "top 80%"
            }
        });

        gsap.set(title.current, { autoAlpha: 0, y: 200 });
        gsap.set(items.current!.children, { autoAlpha: 0, y: 200 });

        tl.current
            .to(title.current, { autoAlpha: 1, y: 0 })
            .to(items.current!.children, { autoAlpha: 1, y: 0, stagger: .1 });
    }, { scope: container, dependencies: [] });

    const Component = display == "grid" ? InitiativeCard :  InitiativeTile;

    return (
        <Section ref={container} background_color={background_color} className={`space-y-8 py-32`}>
            {
                <Container>
                    <StylizedHeading ref={title} as="h2" color={colors.accent} content="Commitments" />
                </Container>
            }
            <Container ref={items} className={`${display == "grid" ? 'grid grid-cols-1 md:grid-cols-2 gap-8' : 'fluid flex flex-col divide-y'}    divide-${colors.accent}`}>
                <>
                    {data?.collections.initiatives.entries.map((initiative, index) => <Component key={initiative.entry.id} number={index + 1} initiative={initiative.entry} colors={colors}/>)}
                </>
            </Container>
        </Section>
    )
}

const GET_INITIATIVES = gql`
query Initiatives {
    collections: collection( handle: "initiatives") {
        initiatives: structure {
            entries: tree {
                entry {
                    id
                    title,
                    ... on Entry_Initiatives_Initiative {
                        content
                    }
                }
            }
        }
    }
}
`;