'use client';

import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { SlowMo } from "gsap/EasePack";
import { useRef } from "react";

import Container from "../../../components/core/container";
import Section from "../../../components/core/section";

import { gql } from "@apollo/client";
import { StylizedHeading } from "@/components/core/heading";
import CommitmentTile from "./tile";
import CommitmentCard from "./card";
import { useThemeColors } from "@/lib/colors";
import { Commitments } from "@/models/accessibility/commitments";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(SlowMo);

interface Props {
    data: Commitments
}


export default function AccessibilityList({ data, ...props}: Props) {

    const container = useRef<HTMLDivElement>(null);
    const title = useRef<HTMLHeadingElement>(null);

    const items = useRef<HTMLDivElement>(null);

    const tl = useRef<gsap.core.Timeline>();

    const colors = useThemeColors("#fbfbfb");

    const display = "grid";

    useGSAP(() => {
        tl.current = gsap.timeline({
            scrollTrigger: {
                trigger: container.current,
                start: "top 80%"
            }
        });

        gsap.set(title.current, { autoAlpha: 0, y: 200 });
        gsap.set(items.current!.children, { autoAlpha: 0, y: 200 });

        tl.current
            .to(title.current, { autoAlpha: 1, y: 0 })
            .to(items.current!.children, { autoAlpha: 1, y: 0, stagger: .1 });
    }, { scope: container, dependencies: [] });

    const Component = display == "grid" ? CommitmentCard :  CommitmentTile;

    return (
        <Section ref={container} background_color="#fbfbfb" className={`space-y-8 py-32`}>
            {
                <Container>
                    <StylizedHeading ref={title} as="h2" color={colors.accent} content="Commitments" />
                </Container>
            }
            <Container ref={items} className={`${display == "grid" ? 'grid grid-cols-1 md:grid-cols-2 gap-8' : 'fluid flex flex-col divide-y'}    divide-${colors.accent}`}>
                <>
                    {data?.collections.commitments.map((commitment, index) => <Component key={commitment.id} number={index + 1} commitment={commitment} colors={colors}/>)}
                </>
            </Container>
        </Section>
    )
}