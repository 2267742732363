'use client';

import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { SlowMo } from "gsap/EasePack";
import { useEffect, useRef, useState } from 'react';
import Container from "../core/container";
import Section from "../core/section";
import './style.css';
import Gallery from "../gallery";
import { useCursor } from "@/modules/cursor/provider";
import { AssetImage } from "@/models/asset";
import Text from "../core/text";
import { useLenis } from "@studio-freight/react-lenis";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(SlowMo);

interface Props {
  words: string[]
  title: string,
  gallery: AssetImage[]
}

export default function HomeHero(props: Props) {
  
  const container = useRef<HTMLDivElement>(null);
  const titles = useRef<HTMLDivElement>(null);
  const cta = useRef<HTMLDivElement>(null);

  const tl = useRef<gsap.core.Timeline>();

  const [ completed, setCompleted ] = useState(false);

  // const words = [
  //   "Hello",
  //   "Visionaries",
  //   "Entrepreneurs",
  //   "Side Hustlers",
  //   "Future CEOS",
  //   "Business Owners",
  //   "Innovators",
  //   "Humanitarians",
  //   "Day Dreamers",
  //   "Those who dare|to dream big",
  // ];

  useGSAP(() => {
    if(titles.current) {
      while(titles.current.children[0]) {
        titles.current.removeChild(titles.current.children[0]);
      }
    }

    if (cta.current) {
      while (cta.current.children[0]) {
        cta.current.removeChild(cta.current.children[0]);
      }
    }

    gsap.set(cta.current, { autoAlpha: 0, scale: 1.2, z: 0.01 });

    tl.current = gsap.timeline({ delay: 0.4 });

    // tl.current = gsap.timeline({
    //   scrollTrigger: {
    //     trigger: container.current,
    //     start: "top 0",
    //     end: "+=500",
    //     pin: true,
    //     scrub: 3,
    //   }
    // });

    const heroBackground = "#home-hero-background";

    var wordCount = props.words.length;
    var time = 0;
    var word;
    var element: HTMLElement;
    var i;
    var duration = 0.3;

    var greeting;
    var greetingDuration = 1;

    greeting = document.createElement("div");
    greeting.classList.add("absolute", "text-title", "invisible", "top-y-1/2", "-translate-y-1/2");
    greeting.innerHTML = "Hello";
    gsap.set(greeting, { color: "#3E3E3D", autoAlpha: 0, scale: 1.2, z: 0.01 });
    titles.current!.append(greeting);

    gsap.to(heroBackground, { backgroundColor: "#F7FF07" })

    tl.current
      .to(heroBackground, { backgroundColor: "#F7FF07", duration: 0.2 })
      .to(greeting, { autoAlpha: 1, scale: 1, ease: "slow(0.7, 0.9, true)", duration: greetingDuration - .1 })
      .to(heroBackground, { backgroundColor: "#ffffff", duration: 0.1 }, greetingDuration)
      .addLabel("Greeting");

    time += greetingDuration + .2;

    for (i = 0; i < wordCount; i++) {

      word = props.words[i];

      element = document.createElement("div");
      element.classList.add("absolute", "text-title", "invisible", "top-y-1/2", "-translate-y-1/2");
      element.innerHTML = word;

      titles.current!.append(element);

      if (i == wordCount - 1) {
        duration = 0.4;

        gsap.set(element, { autoAlpha: 0, scale: 1.2, z: 0.01 });

        tl.current
          .to(element, { autoAlpha: 1, scale: 1, ease: "slow(0.7, 0.9, true)", duration: duration + .5 }, time)
          .addLabel(word);

        time += .6;
      } else {
        gsap.set(element, { autoAlpha: 0, scale: 1.2, z: 0.01 });

        tl.current
          .set(element, { color: "#3E3E3D" })
          .to(element, { autoAlpha: 1, scale: 1, ease: "slow(0.7, 0.9, true)", duration: duration }, time)
          .addLabel(word);
      }

      time += duration - 0.01;
    }

    element = document.createElement("div");
    element.classList.add("absolute", "text-title", "top-y-1/2", "-translate-y-1/2");
    element.innerHTML = props.title;

    cta.current!.append(element);

    tl.current
      .to(heroBackground, { backgroundColor: "#00A6ED", duration: 0.1 })
      .to(cta.current, { autoAlpha: 1, color: "#ffffff", scale: 1.0, duration: duration }, time)
      .addLabel("CTA")
      //.to(heroBackground, { backgroundColor: "#ffffff", duration: 0.4 }, "<2")
      //.to(cta.current, { autoAlpha: 1, color: "#00A6ED", duration: .4 }, "<")
      //.addLabel("CTA")
      .eventCallback("onComplete", () => setCompleted(true));

  }, { dependencies: [props.title, props.words], scope: container });
  
  return (
    <Section ref={container} id="home-hero" className="relative w-full h-screen">
      <div className="w-full overflow-hidden relative z-10">
        <Container className="h-screen flex items-center pointer-events-none touch-none">
          <div ref={titles} className="z-10"></div>
          <div id="home-hero-cta" ref={cta} className="w-full z-10 whitespace-pre-line">
          </div>
        </Container>
      </div>
      <div id="home-hero-background" className="absolute top-0 left-0 w-full h-screen z-[4]"></div>
    </Section>
  )
}