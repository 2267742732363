export function getUri(slug: string | string[]) {
    let uri = "/";

    if (typeof slug !== "undefined") {
        if (Array.isArray(slug)) {
           if(!slug.includes('index')) {
               uri += slug.join("/");
           }
        }
    }

    return uri;
}

export function getSocialMediaIconClass(platform: string) {
    switch (platform.toLowerCase()) {
        case "facebook":
            return "facebook";
        case "instagram":
            return "instagram";
        case "linkedin":
            return "linkedin";
    }
}