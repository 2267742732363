interface ValidationRule {
    validate: (value: any, ruleValue?: any) => boolean;
    message: (field: string, ruleValue?: any, val?: any) => string;
}

interface ValidationRules {
    [rule: string]: ValidationRule;
}

const validationRules: ValidationRules = {
    required: {
        validate: (val: any) => val !== undefined && val !== null && val !== '',
        message: (field: string, ruleValue: any, val: any) => `This field is required`,
    },
    min: {
        validate: (val: string, minValue: number) => val.length >= minValue,
        message: (field: string, minValue: number, val: any) => {

            let unit = "characters";

            switch (typeof (val)) {
                case "object":
                    unit = "items";
                    break;
            }

            return `This field must be at least ${minValue} ${unit}`;
        },
    },
    max: {
        validate: (val: string, maxValue: number) => val.length <= maxValue,
        message: (field: string, maxValue: number, val: any) => {

            let unit = "characters";

            switch (typeof (val)) {
                case "object":
                    unit = "items";
                    break;
            }

            return `This field must be at most ${maxValue} ${unit}`;
        }
    },
    email: {
        validate: (val: string) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(val),
        message: (field: string) => `Invalid email format for field`,
    },  
    // Add more rules as needed
};

export default validationRules