export interface FormData {
    handle: string,
    title: string,
    fields: {
        [key: string]: Field
    },
    api_url: string,
    honeypot: string,
}

export interface Values {
    [key: string]: any,
}

export interface Errors {
    [key: string]: any,
}

export interface Field {
    type: string,
    handle: string,
    display: string,
    width: 25 | 33 | 50 | 66 | 75 | 100,
    validate: string[],
    config?: {
        [key: string]: string
    },
    input_type?: string,
    autocomplete?: string,
    placeholder?: string,
    default?: any,
    options?: { 
        [key: string]: string
    },
    visibility?: "visible" | "hidden" | "readonly",
    multiple?: boolean,
    clearable?: boolean,
    searchable?: boolean,
}

import Form from './form';
import { handleErrorResponse } from './errors';

export default Form;
export { handleErrorResponse }