'use client';
import Container from "@/components/core/container";
import Section from "@/components/core/section";
import Image from "next/image";
import { Heading } from "@/components/core/heading";
import { AssetImage } from "@/models/asset";
import { getAssetUrl } from "@/lib/cms";
import { Project, ProjectClient } from "@/models/project";
// @ts-ignore
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';
import '@splidejs/react-splide/css';
import { useEffect, useState } from "react";

interface Props {
    page: Project,
    background_color: string,
    display: "carousel" | "grid",
    images: AssetImage[]
}

const MINIMUM_WIDTH = 768;

export default function CaseStudySocialMedia(props: Props) {

    const [forceGrid, setForceGrid] = useState<boolean>();

    useEffect(() => {
        const onResize = () => {
            setForceGrid(window.innerWidth < MINIMUM_WIDTH);
        };

        setForceGrid(window.innerWidth < MINIMUM_WIDTH);

        window.addEventListener("resize", onResize);

        return () => window.removeEventListener("resize", onResize);
    }, []);

    return (
        <Section className="overflow-hidden" style={{ backgroundColor: props.background_color }}>
            <Container
                width={props.display == 'grid' ? '7xl' : 'fluid'}
                className={`${forceGrid || props.display == 'grid' ? '' : 'py-32'} flex items-center`}>
                <div className={forceGrid ||props.display == 'grid' ? 'grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mx-4 my-8 md:mx-0 md:my-16' : ''}>
                    {(forceGrid || props.display == "grid") && props.images.map(image =>
                        <Card key={image.id} image={image} />
                    )}
                    {(!forceGrid && props.display == "carousel") &&
                        <Splide className="testimonials"
                            extensions={{ AutoScroll }}
                            options={{
                                type: 'loop',
                                drag: 'free',
                                snap: true,
                                autoWidth: true,
                                gap: "2rem",
                                autoScroll: {
                                    speed: 4,
                                },
                            }}>
                            {props.images.map(image =>
                                <SplideSlide key={image.id} className="my-4">
                                    <Post client={props.page.client} image={image} />
                                </SplideSlide>
                            )}
                        </Splide>

                    }
                </div>
            </Container>
        </Section>
    );
}


function Card({ image }: { image: AssetImage}) {


    return (
        <div className="flex rounded-lg overflow-hidden">
            <Image
                src={getAssetUrl(image)}
                width={image.width}
                height={image.height}
                alt={image.alt ?? ""}
                style={{
                    objectFit: "cover",
                    objectPosition: image.focus_css,
                }} />
        </div>
    )
}

function Post({ client, image }: { client: ProjectClient, image: AssetImage }) {

    return (
        <div className="rounded-lg bg-white border-slate-800 border w-[400px] shrink-0 shadow-xl">
            <div className="flex items-center h-16 p-4">
                <div className="flex items-center space-x-2">
                    <div className="bg-gray-50 rounded-full w-12 h-12 flex items-center justify-center overflow-hidden">
                        { client.icon &&
                            <div className="w-full h-full">
                                <Image
                                    src={getAssetUrl(client.icon)}
                                    width={client.icon.width}
                                    height={client.icon.height}
                                    alt={client.icon.alt ?? ""}
                                    className="w-full h-full"
                                    style={{
                                        objectFit: "contain",
                                        maxHeight: "100%"
                                    }} />
                            </div>
                        }
                    </div>
                     <Heading size="2xl" content={client.title}/>
                </div>
            </div>
            <Image
                src={getAssetUrl(image)}
                width={image.width}
                height={image.height}
                alt={image.alt ?? ""}
                style={{
                    objectFit: "cover",
                    objectPosition: image.focus_css,
                }} />
            <div className="flex items-center h-16 p-4 space-x-4">
                <svg fill="currentColor" height="24" role="img" viewBox="0 0 24 24" width="24"><title>Like</title><path d="M16.792 3.904A4.989 4.989 0 0 1 21.5 9.122c0 3.072-2.652 4.959-5.197 7.222-2.512 2.243-3.865 3.469-4.303 3.752-.477-.309-2.143-1.823-4.303-3.752C5.141 14.072 2.5 12.167 2.5 9.122a4.989 4.989 0 0 1 4.708-5.218 4.21 4.21 0 0 1 3.675 1.941c.84 1.175.98 1.763 1.12 1.763s.278-.588 1.11-1.766a4.17 4.17 0 0 1 3.679-1.938m0-2a6.04 6.04 0 0 0-4.797 2.127 6.052 6.052 0 0 0-4.787-2.127A6.985 6.985 0 0 0 .5 9.122c0 3.61 2.55 5.827 5.015 7.97.283.246.569.494.853.747l1.027.918a44.998 44.998 0 0 0 3.518 3.018 2 2 0 0 0 2.174 0 45.263 45.263 0 0 0 3.626-3.115l.922-.824c.293-.26.59-.519.885-.774 2.334-2.025 4.98-4.32 4.98-7.94a6.985 6.985 0 0 0-6.708-7.218Z"></path></svg>
                <svg fill="currentColor" height="24" role="img" viewBox="0 0 24 24" width="24"><title>Comment</title><path d="M20.656 17.008a9.993 9.993 0 1 0-3.59 3.615L22 22Z" fill="none" stroke="currentColor" strokeLinejoin="round" strokeWidth="2"></path></svg>
                <svg fill="currentColor" height="24" role="img" viewBox="0 0 24 24" width="24"><title>Share Post</title><line fill="none" stroke="currentColor" strokeLinejoin="round" strokeWidth="2" x1="22" x2="9.218" y1="3" y2="10.083"></line><polygon fill="none" points="11.698 20.334 22 3.001 2 3.001 9.218 10.084 11.698 20.334" stroke="currentColor" strokeLinejoin="round" strokeWidth="2"></polygon></svg>
            </div>
        </div>
    )
}