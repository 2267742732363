export interface BookingSuccessfulEvent {
    data: BookingSuccessfulData
    namespace: string
}

export interface BookingSuccessfulData {
    confirmed: boolean,
    date: string,
    duration: number,
    organizer: {
        email: string,
        name: string,
        timeZone: string,
    },
    eventType: {
        id: number,
        slug: string,
        title: string,
        description: string,
        length: number,
    },
    booking: {
        id: number,
        uid: string,
        status: string,
        title: string,
        description: string,
        paid: boolean,
        startTime: string,
        endTime: string,
    }
}

export const CALENDAR_THEME = {
    light: {
        "cal-bg": "#ffffff",
        "cal-brand": "#00A6ED",
        "cal-text": "#3E3E3D",
        "cal-text-emphasis": "#00A6ED",
        "cal-border-emphasis": "#00A6ED",
        "cal-text-error": "red",
        "cal-border": "#00A6ED",
        "cal-border-default": "#00A6ED",
        "cal-border-subtle": "#00A6ED",
        "cal-border-booker": "#00A6ED",
        "cal-text-muted": "#787877",
        "cal-bg-emphasis": "#f1f1f1"
    },
    dark: {
    }
};