'use client';

import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { SlowMo } from "gsap/EasePack";
import { useEffect, useRef, useState } from "react";

import Container from "../../../components/core/container";
import Section from "../../../components/core/section";
import { Heading } from "@/components/core/heading";
import Text from "@/components/core/text";

import { FormData } from "@/modules/form";
import StartFormWrapper from "./wrapper";
import { Button } from "@/components/core/button";
import { Testimonial } from "@/models/testimonials";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(SlowMo);

interface Data {
    scheduling: {
        quick_call: string,
        start_call: string
    },

    pages: {
        start: {
            id: string,
            url: string
        },
        contact: {
            id: string,
            url: string
        }
    },

    collections: {
        testimonials: Testimonial[]
    },
}

interface Props {
    title: string,
    content: string,
    form: FormData,
    data: Data
}

export default function StartForm({data, ...props}: Props) {

    const container = useRef<HTMLDivElement>(null);
    const tl = useRef<gsap.core.Timeline>();

    const [testimonials, setTestimonials] = useState<Testimonial[]>([]);

    const quickCallLink = `${process.env.NODE_ENV === 'development' ? 'https://cal.dev' : 'https://cal.com'}/${process.env.NODE_ENV === 'development' ? '328studios/test' : data.scheduling.quick_call}`

    useEffect(() => {
        const testimonials = [...data.collections.testimonials.filter(testimonial => testimonial.feature)];
        const shuffleArray = (array: Testimonial[]) => {
            const shuffled = array.slice(); // Create a shallow copy of the original array
            for (let i = shuffled.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]]; // Swap elements
            }
            return shuffled;
        };

        // Shuffle the array when the component mounts
        setTestimonials(shuffleArray(testimonials).slice(0, 2));
    }, [data.collections.testimonials]);
    
    useGSAP(() => {
        tl.current = gsap.timeline({
            scrollTrigger: {
                trigger: container.current,
                start: "top 75%"
            }
        });

        gsap.set(container.current, { autoAlpha: 0, y: 200 });

        tl.current
            .to(container.current, { autoAlpha: 1, y: 0 })
    }, { scope: container, dependencies: [] });

    return (
        <Section ref={container} className={`space-y-16 py-16 bg-light-gray`}>
            <Container className="block lg:flex gap-8 space-y-16 lg:space-y-0">
                <div className="space-y-4 w-full lg:w-2/6">
                    <Heading content={props.title} leading="tight" />
                    <Text content={props.content} />
                    <div>
                        <Heading content="Hear it from others:" size="3xl" leading="tight" />
                        <div className="space-y-4">
                            {testimonials.map(testimonial => <div key={testimonial.id}>
                                <Text><em>&quot;{testimonial.feature}&quot;</em> - <span className="text-primary">{testimonial.title}, {testimonial.company}</span></Text>
                                </div>)
                            }
                        </div>
                    </div>
                    <div>
                        <Heading content="Looking for something less?" size="3xl" leading="tight" />
                        <div className="space-y-2 mt-4">
                            <Button block={true} className="w-full block" label="Schedule a Quick Call" href={quickCallLink} target="_blank"/>
                            <Button block={true} className="w-full block" label="Send a Message" href={data.pages.contact.url} />
                        </div>
                    </div>
                </div>
                <StartFormWrapper form={props.form} start_call={data.scheduling.start_call} className="lg:w-4/6"/>
            </Container>
        </Section>
    )
}

