import { ThemeColorSet } from "@/lib/colors";
import { NonProfit } from "@/models/non_profits";
import Link from "next/link";

interface Props {
    non_profit: NonProfit,
    colors: ThemeColorSet
}

export default function Supports({ colors, non_profit, ...props }: Props) {
    
    return (
        <div className="flex items-center flex-wrap gap-2 text-xs md:text-base">
            {non_profit.supports.map(support => <span key={support.id} className={`px-2 py-1 rounded-md bg-${colors.accent} text-${colors.accent_text}`}>{support.title}</span>)}
        </div>
    );
}