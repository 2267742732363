'use client';

import { Field, FormData, Values, Errors } from "@/modules/form";

import { FormField } from "../field";
import { FormErrors } from "../errors";

import { useCallback, useEffect, useState } from "react";
import validationRules from "../validate";

interface Props {
    form: FormData,
    data?: Values,
    errors?: Errors,
    onChange?: ((values: any, validated: boolean) => void)
    onSubmit?: ((values: any) => void)
}

export default function Form({ form, data, errors: passedErrors, onChange }: Props) {

    const [validated, setValidated] = useState(false);
    const [values, setValues] = useState<Values>(data ?? {});
    const [errors, setErrors] = useState<Errors>({});

    const validateField = useCallback((field: Field, value: any, notify: boolean = true) => {
        const fieldRules = field.validate ?? [];

        let fieldValidated = true;

        const fieldErrors: string[] = [];

        fieldValidated = fieldRules.every(rule => {
            return validate(rule, field, value, fieldErrors);
        });

        if (notify) {
            setErrors({ ...errors, [field.handle]: fieldErrors });
        }

        return fieldValidated;
    }, [errors]);

    const validate = (rule: string, field: Field, value: any, errors: string[]) => {
        const parts = rule.split(':');

        const ruleName = parts[0];
        const ruleValue = parts[1];

        if (validationRules[ruleName]) {
            const isValid = validationRules[ruleName].validate(value, ruleValue);

            if (!isValid) {
                const errorMessage = validationRules[ruleName].message(field.display, ruleValue, value);

                errors.push(errorMessage);

                return false;
            }
        }

        return true;
    }

    const validateForm = useCallback((values: Values) => {

        const validated = Object.keys(form.fields).every(handle => {

            const field = form.fields[handle];
            const value = values[handle];

            return validateField(field, value, false);
        });

        setValidated(validated);
    }, [form.fields, validateField])

    useEffect(() => {
        if (typeof onChange !== "undefined") {
            onChange(values, validated);
            validateForm(values);
        }
    }, [onChange, validateForm, values, validated])

    const onFieldChange = (handle: string, value: any) => {
        setValues({ ...values, [handle]: value });
    }

    const onFieldBlur = (handle: string, value: any) => {
        const field = form.fields[handle];

        return validateField(field, value);
    }

    return (
        <form method="post" className="form space-y-4">
            <FormErrors form={form} errors={passedErrors} />
            <div className="flex flex-wrap">
                {
                    Object.keys(form.fields).map(handle => <FormField key={handle} form={form} field={form.fields[handle]} value={values[handle]} errors={errors[handle]} onChange={(value) => onFieldChange(handle, value)} onBlur={(value) => onFieldBlur(handle, value)} />)
                }
            </div>
            <input type="text" name={form.honeypot} style={{ display: "none" }} />
        </form>
    )
}

