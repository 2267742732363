import { RefObject, useEffect } from "react";
import { gsap } from "gsap";
import { useCursor, CursorEffects } from "./provider";

export function useCursorEffects(
    element: RefObject<HTMLElement>,
    onCursorEnter: CursorEffects | (() => CursorEffects),
    onCursorLeave?: CursorEffects | (() => CursorEffects)
) {

    const { expandCursor, minimizeCursor, changeCursorColor } = useCursor();
    
    useEffect(() => {
        const currentElement = element.current;

        let defaultOnCursorEnter;

        if (typeof onCursorEnter === 'function') {
            defaultOnCursorEnter = (e: MouseEvent) => {
                e.stopImmediatePropagation();
                const effects = onCursorEnter();
                
                if(effects.text) {
                    expandCursor(effects.text, effects.color, effects.textColor);
                } else {
                    changeCursorColor(effects.color);
                }
            }
        } else {
            defaultOnCursorEnter = (e: MouseEvent) => {
                e.stopImmediatePropagation();

                if(onCursorEnter.text) {
                    expandCursor(onCursorEnter.text, onCursorEnter.color, onCursorEnter.textColor);
                } else {
                    changeCursorColor(onCursorEnter.color);
                }
            }
        }


        let defaultOnCursorLeave;

        if (typeof onCursorLeave === 'function') {
            defaultOnCursorLeave = () => {
                const effects = onCursorLeave();
                minimizeCursor(effects.color);
            }
        } else {
            defaultOnCursorLeave = () => {
                minimizeCursor(onCursorLeave ? onCursorLeave.color : null);
            }
        }

        currentElement?.addEventListener("mouseenter", defaultOnCursorEnter);
        currentElement?.addEventListener("mousemove", defaultOnCursorEnter);
        currentElement?.addEventListener("mouseleave", defaultOnCursorLeave);

       
        return () => {
            if (typeof onCursorEnter === 'function') {
                currentElement?.removeEventListener("mouseneter", onCursorEnter);
                currentElement?.removeEventListener("mousemove", onCursorEnter);
            }

            if (typeof onCursorLeave === 'function') {
                currentElement?.removeEventListener("mouseleave", onCursorLeave);
            }
        }
    }, [changeCursorColor, element, expandCursor, minimizeCursor, onCursorEnter, onCursorLeave])
}