'use client';

import { useThemeColors } from "@/lib/colors";
import Heading, { HeadingProps } from "./basic";
import { ForwardedRef, forwardRef, useRef } from 'react';
import { useCursorEffects } from "@/modules/cursor/useCursorEffects";
import React from "react";

interface StylizedProps extends HeadingProps {
    color?: string,
    fit?: boolean,
    forwardedRef?: React.Ref<HTMLDivElement>
}

const StylizedHeading = forwardRef(function StylizedHeading({ color = "tertiary", size = "4xl", ...props }: StylizedProps, ref: ForwardedRef<HTMLDivElement>) {

    const container = useForwadedRef(ref);

    const themeColor = useThemeColors(color);
    useCursorEffects(container, { color: themeColor.cursor });
    
    const getTextColor = () => {
        switch (color) {
            case "primary":
                return "white";
            case "secondary":
                return "tertiary";
            case "tertiary":
                return "secondary";
            case "white":
                return "secondary";
        }
    }
    return (
        <div ref={container} className={`bg-${color} p-4 ${props.fit ? 'inline-block' : 'max-w-sm'}`}>
            <Heading ref={ref} className="w-full max-w-full" as="span" color={getTextColor()} leading="none" size={size} transform="uppercase" {...props}/>
        </div>
    )
});

function useForwadedRef(ref: ForwardedRef<HTMLDivElement>) {
    const innerRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        if (!ref) return;
        if (typeof ref === 'function') {
            ref(innerRef.current);
        } else {
            ref.current = innerRef.current;
        }
    });

    return innerRef;
}

export default StylizedHeading;