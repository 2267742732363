'use client';

import { Button } from "@/components/core/button";
import Text from "@/components/core/text";
import Container from "@/components/core/container"
import Section from "@/components/core/section"
import PageHero from "@/components/hero/page"
import { useEffect } from "react"

export default function Error({
    error,
    reset,
}: {
    error: Error & { digest?: string }
    reset: () => void
}) {

    useEffect(() => {
        // Log the error to an error reporting service
        console.error(error)
    }, [error])

    return (
        <>
            <PageHero page_title="Error" title="Hmmm, that didn't go according to plan" subtitle="We're not sure what happened"/>
            <Section className="py-16">
                <Container width="xl" className="space-y-4 flex flex-col items-center justify-center">
                    <Text align="center">We&apos;re sorry this happened. It&apos;s not you, it&apos;s definitely us. To make it up, enjoy <strong>20% off</strong> your first project. Can we still be friends?</Text>
                    <Button href="/" label="Start Now" />
                </Container>
            </Section>
        </>
    )
}