'use client';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useRef } from "react";
import { Button, ButtonCircle } from "@/components/core/button";
import { Heading } from "@/components/core/heading";
import Text from "@/components/core/text";
import Container from "@/components/core/container";
import Image from 'next/image'
import Section from "@/components/core/section";
import { useThemeColors } from "@/lib/colors";
import { AssetImage } from "@/models/asset";
import { getAssetUrl } from "@/lib/cms";

gsap.registerPlugin(ScrollTrigger);


interface Props {
    background_image: AssetImage | null,
}

export default function LaunchCTA(props: Props) {
    const content = useRef<HTMLDivElement>(null);

    const colors = useThemeColors("yellow");

    return (
        <Section overlay_color="secondary" overlay_gradient="bg-gradient-to-t from-secondary" overlay_opacity={95} background_mix="soft-light" background_image={props.background_image} background_position="43% 100%" className="mx-4 lg:mx-8 my-8 rounded-xl overflow-hidden">
            <Container className={`flex items-end h-[800px] py-8`}>
                <Container width="2xl" className={`flex items-end h-full`}>
                    <div ref={content} className="flex flex-col justify-center w-full space-y-4 text-center">
                        <div>
                            <Heading as="h2" leading="tight" transform="uppercase" content="Launch Your Business" color="primary" align="center"></Heading>
                        </div>
                        <Text align="center" color="white" content="Starting a business can feel overwhelming, but with Launch by 328 Studios, it's never been easier. Let us take care of the paperwork, so you can focus on growing your business." />
                        <div className="flex flex-col items-center">
                            <Button label="Launch Your LLC Now" theme_color="primary" href="https://launch.328studios.co/" target="_blank"/>
                            <span className="text-white">For $0 + State Fee</span>
                        </div>
                    </div>
                </Container>
            </Container>
        </Section>
    );
}