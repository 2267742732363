'use client';
import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { SlowMo } from "gsap/EasePack";
import { useRef, useState, useEffect } from "react";
import ProjectCard from "../list/card";
import ScrollingHeading from "@/components/core/heading/scrolling";
import { Project } from "@/models/project";
import Container from "../../../components/core/container";
import Section from "../../../components/core/section";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(SlowMo);

interface Props {
    title: string,
    projects: Project[];
    featured_project: any;
}

export default function ProjectFeatured(props: Props) {
    const container = useRef<HTMLDivElement>(null);

    const title = useRef<HTMLDivElement>(null);
    const projects = useRef<HTMLDivElement>(null);

    const tl = useRef<gsap.core.Timeline>();

    useGSAP(() => {
        tl.current = gsap.timeline({
            scrollTrigger: {
                trigger: title.current,
                start: "top 80%",
                end: "bottom 80%"
            }
        });

        gsap.set(title.current, { autoAlpha: 0 });
        gsap.set(projects.current!.children, { autoAlpha: 0, y: 200 });

        tl.current
            .to(title.current, { autoAlpha: 1, y: 0 })
            .to(projects.current!.children, { autoAlpha: 1, y: 0, stagger: .2 });
    }, { scope: container, dependencies: [] });

    return (
        <>
            <Section ref={container} background_color="light-gray" className="pt-32">
                {props.featured_project &&
                    <Container width="fluid" className="light-gray pt-32">
                        <ProjectCard featured={true} project={props.featured_project} />
                    </Container>
                }
                {props.projects && props.projects.length &&
                    <Section background_color="tertiary" className="space-y-8 py-32">
                        <Container width="fluid" ref={title} className="flex flex-col items-center justify-center ">
                            <ScrollingHeading color="primary" transform="uppercase" size="20xl">{props.title.trim()}</ScrollingHeading>
                        </Container>
                        <Container className="space-y-8 md:space-y-0 text-secondary">
                            <div ref={projects} className="grid grid-cols-1 md:grid-cols-2 gap-8 space-y-8 md:space-y-0">
                                {props.projects.map((project, index) =>
                                    <ProjectCard key={index} featured={false} project={project} />
                                )}
                            </div>
                        </Container>
                    </Section>
                }
            </Section>
        </>
    )
}