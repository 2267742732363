import { Heading } from "@/components/core/heading"
import Text from "@/components/core/text"
import { NavigationLink } from "@/models/navigation"
import Link from "next/link"
import { MouseEventHandler } from "react"

interface Props {
    clone?: boolean,
    index: number,
    link: NavigationLink,
    onMouseEnter: MouseEventHandler<HTMLAnchorElement>,
    onMouseLeave: MouseEventHandler<HTMLAnchorElement>,
    onClick: MouseEventHandler<HTMLAnchorElement>
}

export function MenuItem({ clone = false, ...props }: Props) {

    return (
        <li key={props.link.page.id} className={`absolute menu-item ${clone ? 'menu-item-clone' : ''}`} aria-hidden={clone}>
            <Link href={props.link.page.url} target={props.link.page.external ? '_blank' : '_self'} className={`inline-block ${props.link.page.external ? 'external-link' : ''}`} data-title={props.link.page.title} onMouseEnter={props.onMouseEnter} onMouseLeave={props.onMouseLeave} onClick={props.onClick} prefetch={false}>
                <div className="flex space-x-4">
                    <div className="mt-8 hidden">
                        <div className="bg-secondary p-2 flex items-center justify-between">
                            <Text content={`0${props.index + 1}`} size="2xl" color="white" />
                        </div>
                    </div>
                    <Heading className="label text-nav" size="title" transform='uppercase'>{props.link.page.title}</Heading>
                </div>
            </Link>
        </li>
    )
}