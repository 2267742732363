'use client';
import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { SlowMo } from "gsap/EasePack";
import { useRef, useState, useEffect, MouseEventHandler, MouseEvent } from "react";
import ProjectCard from "./card";
import { Heading } from "@/components/core/heading";
import Text from '@/components/core/text';
import { ProjectsWithFilters } from "@/models/project";
import Container from "../../../components/core/container";
import Section from "../../../components/core/section";
import { gql } from "@apollo/client";
import { ThemeColorSet, colorToHex, useThemeColors } from "@/lib/colors";
import usePersistedQuery from "@/hooks/usePersistedQuery";
import dynamic from "next/dynamic";
import "./style.css";

const Select = dynamic(() => import("react-select"), { ssr: false });


gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(SlowMo);

interface Props {
    title: string | null,
    columns: 2 | 3 | 4,
    filterable: boolean,
    background_color: string,
    data?: ProjectsWithFilters
}

export default function ProjectList(props: Props) {
    
    const [data, setData] = useState<ProjectsWithFilters|undefined>(props.data);
    const [initialized, setInitialized] = useState<boolean>(false);
    const [filterOptions, setFilterOptions] = useState<{ value: any, label: string }[]>([]);
    const [ currentFilter, setCurrentFilter ] = useState<string|null>(null);
    const [currentFilterReact, setCurrentFilterReact] = useState<DOMRect | null>(null);

    const { loading, error, data: queriedData, refetch } = usePersistedQuery<ProjectsWithFilters>(GET_PROJECTS, {
        pollInterval: 1000 * 60 * 5,
        variables: {
            filter: currentFilter
        }
    });

    const container = useRef<HTMLDivElement>(null);

    const filtersContainer = useRef<HTMLDivElement>(null);
    const filters = useRef<HTMLDivElement>(null);
    const projects = useRef<HTMLDivElement>(null);

    const gsapContext = useRef<gsap.Context>();
    const tl = useRef<gsap.core.Timeline>();
    const tl2 = useRef<gsap.core.Timeline>();

    const colors = useThemeColors(props.background_color, true);

    const style = {
        "--accent-color": colors.accent,
        "--accent-text-color": colors.accent_text
    } as React.CSSProperties;

    const columns: { [keys in 2 |3 |4]: string} = {
        2: 'lg:grid-cols-2',
        3: 'lg:grid-cols-3',
        4: 'lg:grid-cols-4',
    }

    // const onFilterChange = (event: MouseEvent<HTMLButtonElement> | null, filter: string | null) => {

    //     // if (projects.current) {
    //     //     const cards = gsap.utils.toArray(projects.current!.children);
    //     //     gsap.set(cards, { autoAlpha: 0, y: 200 });
    //     // }

    //     filter = filter ? filter.slice(filter.indexOf("::") + 2) : null;

    //     setCurrentFilter(filter);

    //     moveFilterButton(event!.currentTarget);
        
    //     refetch({
    //         filter: filter
    //     });
    // };

    const onFilterChange = (selectedOption: any) => {
        let filter = (selectedOption as { value: any, label: string }).value;
        

        if (projects.current) {
            const cards = gsap.utils.toArray(projects.current!.children);
            gsap.set(cards, { autoAlpha: 0, y: 200 });
        }

        filter = filter ? filter.slice(filter.indexOf("::") + 2) : null;

        setCurrentFilter(filter);
        
        refetch({
            filter: filter
        });
    }


    const moveFilterButton = (target: HTMLElement | null) => {
        if (!target) {
            setCurrentFilterReact(null);
            return;
        };

        const parent = target.parentElement;
        const parentRect = parent!.getBoundingClientRect();

        const rect = target!.getBoundingClientRect();

        const boldness = 4;
        // let height = rect.height + 16 + boldness;
        // let width = rect.width + 32 + boldness;

        const modifiedRect = Object.assign(rect.toJSON(), {
            top: rect.y - parentRect.y + (rect.height / 2) + 8,
            left: rect.x - parentRect.x + 16 
        });

        setCurrentFilterReact(modifiedRect);
    }

    useGSAP((context) => {
        if (!projects.current) return;

        gsapContext.current = context;

        context.add("enter", () => {
            tl.current = gsap.timeline({
                scrollTrigger: {
                    trigger: container.current,
                    start: "top 50%"
                }
            });

            if (props.filterable) {
                gsap.set(filtersContainer.current, { autoAlpha: 0, y: 200 });

                tl.current
                    .to(filtersContainer.current, { autoAlpha: 1, y: 0, stagger: .1 });
            }
        });

        context.add("animateProjects", () => {
            tl2.current = gsap.timeline();

            gsap.set(projects.current!.children, { autoAlpha: 0, y: 200 });

            tl2.current
                .to(projects.current!.children, { autoAlpha: 1, y: 0, stagger: .1 });
        });

    }, { scope: container, dependencies: [loading, props.filterable] });

    useEffect(() => {
        if (data) {
            if (!initialized) {
                let options: { value: any, label: string }[] = [{value: null, label: "All"}];

                options = [...options, ...data.taxonomy.terms.map(term => {
                    return { value: term.id, label: term.title };
                }) ];

                setFilterOptions(options);

                setInitialized(true);
                gsapContext.current && gsapContext.current.enter();
            } 
        }
    }, [initialized, data]);

    useEffect(() => {
        if (initialized) {
            if (filters.current) {
                moveFilterButton(filters.current.querySelector("button"));
            }
        }
    }, [initialized])

    
    useEffect(() => {
        if (queriedData) {
            setData(queriedData);
            gsapContext.current && gsapContext.current.animateProjects();
        }
    }, [queriedData, setData]);

    if (!data) {
        return <></>;
    }

    return (
        <Section ref={container} background_color={colors.background} className="space-y-8 py-32 min-h-full">
            {props.filterable &&
            // <Container ref={filtersContainer} className="position relative flex justify-center py-8">
            //     <div ref={filters}  className="flex w-full space-x-4 z-10">
            //         <Button colors={colors} active={currentFilter == null} content="All" onClick={(event) => onFilterChange(event, null)} />
            //         {data.taxonomy.terms.map(term =>
            //             <Button  key={term.id} colors={colors} active={currentFilter == (term.id.slice(term.id.indexOf("::") + 2))} content={term.title} onClick={(event) => onFilterChange(event, term.id)}/>
            //         )}
            //     </div>
            //         <div className={`absolute px-4 py-2 rounded-lg transition-all ease-in-out duration-300 bg-${colors.accent}`} style={{
            //             top: `${currentFilterReact?.top}px`,
            //             left: `${currentFilterReact?.left}px`,
            //             width: `${currentFilterReact?.width}px`,
            //             height: `${currentFilterReact?.height}px`,
            //         }}></div>
            // </Container>
            <Container ref={filtersContainer} className="position relative py-8">
                    <div className="max-w-sm flex flex-col space-y-2">
                        <Select
                            styles={{
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    backgroundColor: colors.accent,
                                    fontWeight: "bold",
                                    color: colors.accent_text
                                }),
                                singleValue: (baseStyles, state) => ({
                                    ...baseStyles,
                                    color: colors.accent_text
                                }),
                                dropdownIndicator: (baseStyles, state) => ({
                                    ...baseStyles,
                                    color: colors.accent_text
                                }),
                                option: (baseStyles, state) => ({
                                    ...baseStyles,
                                    backgroundColor: state.isFocused ? colors.accent : '#ffffff',
                                    color: state.isFocused ? colors.accent_text : colorToHex("secondary")
                                }),
                                
                            }}
                            className="filter-select"
                            classNamePrefix="select"
                            isClearable={false}
                            isSearchable={false}
                            options={filterOptions}
                            defaultValue={filterOptions[0]}
                            onChange={onFilterChange} />
                    </div>
            </Container>
            }
            <Container className="space-y-8 md:space-y-0 text-secondary min-h-[50vh]">
                <div ref={projects} className={`grid grid-cols-1 md:grid-cols-2 ${columns[props.columns]} gap-8 space-y-8 md:space-y-0`}>
                    {data.collections.projects && data.collections?.projects.map((project, index) =>
                        <ProjectCard key={index} featured={false} project={project} />
                    )}
                </div>
            </Container>
            <Container width="2xl" className="flex justify-center items-center pt-16">
                <div className={`flex items-center w-full p-16 rounded-lg space-x-4`} style={{
                    backgroundColor: colors.accent,
                    color: colorToHex(colors.accent_text)
                }}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="w-24 h-24">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z" />
                    </svg>
                    <div>
                        <Heading size="4xl" content="We're still moving in" />
                        <Text as="p" size="2xl" content="Check back later as we continue to add our old projects." />
                    </div>
                </div>
            </Container>
        </Section>
    )
}

const Button = ({ colors, active, content, onClick }: { colors: ThemeColorSet, active: boolean, content: string, onClick: MouseEventHandler<HTMLButtonElement> }) => {
    return (
        <button onClick={onClick} className="rounded-lg px-4 py-2 leading-none transition-all duration-300">
            <Text size="2xl" weight="bold" color={active ? colors.accent_text : colors.text} content={content} />
        </button>
    )
}

const GET_PROJECTS = gql`
query Projects($sort: String = "completed desc", $filter: String = null) {
   	taxonomy: terms( taxonomy: "services") {
        current_page
        terms: data {
            id
            title
        }
    }
  
  	collections: entries( collection: "projects", sort: [$sort], filter: {
        services: { contains: $filter }
    }) {
    	... on EntryInterfacePagination {
            projects: data {
                id,
                status,
                published,
                private,
                title,
                url,
                permalink,
                ... on Entry_Projects_Project {
                    cover_images {
                        id,
                        url,
                        permalink,
                        width,
                        height,
                        size,
                        ratio,
                        ... on Asset_Assets {
                            alt,
                            focus_css,
                        }
                    }
                    client {
                        id,
                        title
                    },
                    services {
                        id,
                        title
                    },
                    project_title
                }
            }
        }
    }
}
`;