"use client";

import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { SlowMo } from "gsap/EasePack";
import { useRef } from "react";

import Container from "../../../components/core/container";
import Section from "../../../components/core/section";

import { gql } from "@apollo/client";
import { StylizedHeading } from "@/components/core/heading";
import ServiceTile from "./tile";
import ServiceCard from "./card";
import { useThemeColors } from "@/lib/colors";
import { Services } from "@/models/services";
import { useCursorEffects } from "@/modules/cursor/useCursorEffects";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(SlowMo);

interface Props {
    title: string,
    display: "list" | "grid",
    background_color: string,
    open_page: boolean,
    show_title: boolean,
    data?: Services
}

export default function ServicesList({ data, background_color, display, ...props}: Props) {
    
    const container = useRef<HTMLDivElement>(null);
    const title = useRef<HTMLHeadingElement>(null);

    const items = useRef<HTMLDivElement>(null);

    const tl = useRef<gsap.core.Timeline>();
    const tl2 = useRef<gsap.core.Timeline>();

    const colors = useThemeColors(background_color);

    useCursorEffects(container, { color: colors.cursor });

    useGSAP(() => {

        tl.current = gsap.timeline({
            scrollTrigger: {
                trigger: container.current,
                start: "top 80%"
            }
        });

        tl2.current = gsap.timeline({
            delay:.5,
            scrollTrigger: {
                trigger: container.current,
                start: "top 80%"
            }
        });

        gsap.set(items.current!.children, { autoAlpha: 0, y: 200 });

        if (props.show_title && title.current) {
            gsap.set(title.current, { autoAlpha: 0, y: 200 });

            tl.current
                .to(title.current, { autoAlpha: 1, y: 0 })
        }

        tl2.current
            .to(items.current!.children, { autoAlpha: 1, y: 0, stagger: .1 });
            
    }, { scope: container, dependencies: [props.show_title, title] });

    const Component = display == "grid" ? ServiceCard :  ServiceTile;

    return (
        <Section ref={container} background_color={background_color} className={`space-y-8 py-32`}>
            {
                props.show_title &&
                <Container>
                    <StylizedHeading ref={title} as="h2" color={colors.accent} content={props.title ?? "Services"} />
                </Container>
            }
            <Container ref={items} className={`${display == "grid" ? 'grid grid-cols-1 md:grid-cols-2 gap-8' : 'fluid flex flex-col divide-y'}    divide-${colors.accent}`}>
                <>
                    {data?.collections.services.entries.map((service, index) => <Component key={service.entry.id} number={index + 1} service={service.entry} open_page={props.open_page} colors={colors}/>)}
                </>
            </Container>
        </Section>
    )
}

export const GET_SERVICES = `
query Services {
    collections: collection( handle: "services") {
        services : structure {
            entries : tree {
                entry {
                    id,
                    title,
                    url,
                    ... on Entry_Services_Service {
                        services
                    }
                }
            }
        }
    }
}
`;