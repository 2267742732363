'use client';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect, useRef } from "react";
import Container from "@/components/core/container";
import Section from "@/components/core/section";
import Text from "@/components/core/text";
import { Heading } from "@/components/core/heading";
import { colorToHex } from "@/lib/colors";

gsap.registerPlugin(ScrollTrigger);

interface Color { 
    id: string,
    name: string,
    color: string
}

interface Props {
    colors: Color[]
}

export default function CaseStudyColors(props: Props) {

    const tl = useRef<gsap.core.Timeline>();
    const container = useRef<HTMLDivElement>(null);

    useEffect(() => {
        tl.current = gsap.timeline();

        //gsap.set(container.current!.children, { autoAlpha: 0, y: 200 });

        // tl.current
        //     .to(container.current!.children, { autoAlpha: 1, y: 0, stagger: 0.1 });

    }, []);

    return (
        <Section>
            <Container ref={container} width="7xl" className="flex gap-4">
                {props.colors.map(color =>
                    <Card key={color.id} color={color}/>
                )}
            </Container>
        </Section>
    );
}

interface CardProps {
    color: Color
}

function Card({ color }: CardProps) {

    const hexToRgb = (hex: string) => {
        // Convert a hex color to RGB values
        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        
        return result ? [
            parseInt(result[1], 16),
            parseInt(result[2], 16),
            parseInt(result[3], 16)
        ] : [0,0,0];
    }

    const getContrastColor = (hex: string) => {
        // Convert the color to RGB
        const rgb = hexToRgb(hex);

        // Calculate the relative luminance using the sRGB luminance formula
        const luminance = (0.2126 * rgb[0] / 255) + (0.7152 * rgb[1] / 255) + (0.0722 * rgb[2] / 255);

        // Set the contrast color based on the luminance
        return luminance > 0.5 ? colorToHex("secondary") : colorToHex("white");
    }

    return (
        <div className="rounded-lg h-0 pt-[60%] md:pt-[120%] w-full relative"
            style={{ backgroundColor: color.color}}
            >
            <div className="flex flex-col absolute bottom-2 left-2  md:bottom-4 md:left-4  uppercase"
                style={{ color: getContrastColor(color.color)}}
                >
                <span className="font-bold font-display text-xl md:text-2xl leading-tight">{color.name}</span>
                <span className="text-base md:text-lg">{color.color}</span>
            </div>
        </div>
    )
}