import Config from '../config';

interface FetchData {
    [key: string]: any,
}

export interface Client {
    get: (path: string, options?: {}) => Promise<Response>;
    post: (path: string, data?: {}, options?: {}) => Promise<Response>;
    put: (path: string, data?: {}, options?: {}) => Promise<Response>;
    patch: (path: string, data?: {}, options?: {}) => Promise<Response>;
    delete: (path: string, data?: {}, options?: {}) => Promise<Response>;
}

(async () => {
    const rootUrl = process.env.NODE_ENV === 'development' ? Config.url.development : Config.url.production;
    
    const csrfUrl = `${rootUrl}/sanctum/csrf-cookie`;

    await fetch(csrfUrl, {
        credentials: "include",
    })
    .catch(err => console.error(err));
})();

export const getClient: () => Client = () => {
    const rootUrl = process.env.NODE_ENV === 'development' ? Config.url.development : Config.url.production;

    const getXSRFToken = (): string => {
        let name = "XSRF-TOKEN";
        name = encodeURIComponent(name);
        let regexp = new RegExp('(?:^' + name + '|;\\s*' + name + ')=(.*?)(?:;|$)', 'g');
        let result = regexp.exec(document.cookie);
        return result ? decodeURIComponent(result[1]) : "";
    }

    const getHeaders = (): HeadersInit => {
        const xsrf = getXSRFToken();

        return new Headers({
            'Content-Type': 'application/json',
            'X-XSRF-TOKEN': xsrf,
            'X-Requested-With': 'XMLHttpRequest'
        });
    }

    return {
        get: (path: string, options: RequestInit = {}) => {
            path = path.replace(rootUrl, "");
            const url = `${rootUrl}/${path}`;

            return fetch(url, options);
        },
        post: (path: string, data: FetchData = {}, options: RequestInit = {}) => {
            path = path.replace(rootUrl, "");
            const url = `${rootUrl}/${path}`;

            return fetch(url, {
                ...options,
                credentials: "include",
                method: "POST",
                headers: getHeaders(),
                body: JSON.stringify(data)
            })
            .then(response => {
                if (!response.ok) {
                    throw response;
                }

                return response;
            });
        },
        put: (path: string, data: FetchData = {}, options: RequestInit = {}) => {
            path = path.replace(rootUrl, "");
            const url = `${rootUrl}/${path}`;

            return fetch(url, {
                ...options,
                credentials: "include",
                method: "PUT",
                headers: getHeaders(),
                body: JSON.stringify(data)
            })
            .then(response => {
                if (!response.ok) {
                    throw response;
                }

                return response;
            });
        },
        patch: (path: string, data: FetchData = {}, options: RequestInit = {}) => {
            path = path.replace(rootUrl, "");
            const url = `${rootUrl}/${path}`;

            return fetch(url, {
                ...options,
                credentials: "include",
                method: "PATCH",
                headers: getHeaders(),
                body: JSON.stringify(data)
            })
            .then(response => {
                if (!response.ok) {
                    throw response;
                }

                return response;
            });
        },
        delete: (path: string, data: FetchData = {}, options: RequestInit = {}) => {
            path = path.replace(rootUrl, "");
            const url = `${rootUrl}/${path}`;

            return fetch(url, {
                ...options,
                credentials: "include",
                method: "DELETE",
                headers: getHeaders(),
                body: JSON.stringify(data)
            })
            .then(response => {
                if (!response.ok) {
                    throw response;
                }

                return response;
            });
        }
    };
};
