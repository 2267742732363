import ScrollingHeading from "@/components/core/heading/scrolling";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { useEffect, useRef, useState } from "react";

interface MenuBackgroundProps {
    open: boolean | null,
    word: string | null,
    onEnterComplete: () => void,
    onExitComplete: () => void
}

export default function MenuBackground(props: MenuBackgroundProps) {
    const container = useRef<HTMLDivElement>(null);
    const background = useRef<HTMLDivElement>(null);

    const gsapContext = useRef<gsap.Context>();
    const tl = useRef<gsap.core.Timeline>();

    useGSAP((context) => {
        gsapContext.current = context;

        var duration = 0.3;
        var stagger = 0.2;

        gsap.set(background.current!.children, { xPercent: -100 });

        tl.current = gsap.timeline();

        context.add("enter", () => {
            gsap.set(background.current!.children, { xPercent: -100 });

            tl.current!
                .to(background.current!.children, { xPercent: 0, duration: duration, stagger: { from: 2, each: stagger }, ease: "power2.out" })
                .eventCallback("onComplete", () => props.onEnterComplete());
        });

        context.add("exit", () => {
            tl.current!
                .to(background.current!.children, { xPercent: 100, duration: duration, delay: 1, stagger: { from: 0, each: stagger }, ease: "power2.out" })
                .set(background.current!.children, { xPercent: -100 })
                .eventCallback("onComplete", () => props.onExitComplete());
        });

    }, { scope: container, dependencies: [] });

    useEffect(() => {
        if (!gsapContext.current) return;

        if (props.open === true) {
            gsapContext.current.enter();
        } else if (props.open === false) {
            gsapContext.current.exit();
        }
    }, [props.open]);

    return (
        <div ref={container} className="fixed top-0 left-0 w-screen h-full" aria-hidden={true}>
            {props.word &&
                <div className="scrolling-background flex flex-col w-full h-full items-start">
                    <MenuScrollingTextBackground word={props.word} />
                </div>
            }
            <div ref={background}>
                <div className="absolute top-0 left-0 bg-tertiary w-screen h-screen" style={{ zIndex: 3 }}></div>
                <div className="absolute top-0 left-0 bg-secondary w-screen h-screen" style={{ zIndex: 2 }}></div>
                <div className="absolute top-0 left-0 bg-primary w-screen h-screen" style={{ zIndex: 1 }}></div>
            </div>
        </div>
    )
}

interface MenuScrollingTextBackgroundProps {
    word: string
}

function MenuScrollingTextBackground(props: MenuScrollingTextBackgroundProps) {

    const container = useRef<HTMLDivElement>(null);

    const [instances, setInstances] = useState(1);

    useEffect(() => {

        const updateInstances = () => {

            if (!container.current || !container.current.children[0]) return;

            const containerHeight = container.current.offsetHeight;

            const heading = container.current.children[0];
            const headingReact = heading.getBoundingClientRect();

            const numInstances = Math.ceil((containerHeight / headingReact.height));

            setInstances(numInstances);
        };

        window.addEventListener('resize', updateInstances);

        updateInstances();

        return () => {
            window.removeEventListener('resize', updateInstances);
        };
    }, [container])

    return (
        <div ref={container} className='h-full w-full'>
            {
                [...Array(instances)].map((i, index) => <ScrollingHeading key={index} size='14xl' forward={(index + 1) % 2 == 0} speed={4}>{props.word.toUpperCase()}</ScrollingHeading>)
            }
        </div>
    )
}