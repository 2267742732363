'use client';
import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import { ForwardedRef, forwardRef, useEffect, useRef, useState } from "react";
import { useCursor } from "./provider";


interface Props {

}

export default function Cursor(props: Props) {
    const cursor = useRef<HTMLDivElement>(null);

    const gsapContext = useRef<gsap.Context>();
    const tl = useRef<gsap.core.Timeline>();

    const { expanded, color, afterMinimizeCursor } = useCursor();

    const size = 20;
    const sizeExpanded = 120;
    const border = 4;

    useGSAP((context) => {
        gsapContext.current = context;

        const { innerWidth, innerHeight } = window;
        var x = innerWidth / 2;
        var y = innerHeight / 2;

        gsap.set(cursor.current, { x, y });
        gsap.set(cursor.current, { x, y });

        context.add("onMove", ({ clientX, clientY }: MouseEvent) => {
            gsap.to(cursor.current, { x: clientX, y: clientY });
            gsap.to(cursor.current, { x: clientX, y: clientY });
        });

        window.addEventListener("pointermove", context.onMove);

        context.add("expand", () => {
            tl.current = gsap.timeline({});
            tl.current
                .to(".cursor-text", {
                    scale: 1,
                    ease: "back.in",
                    color: color ?? "#F7FF07",
                })
                .to(cursor.current, {
                    width: sizeExpanded,
                    height: sizeExpanded,
                    backgroundColor: "transparent",
                    borderWidth: border,
                    borderColor: color ?? "#F7FF07",
                    ease: "back.out",
                }, "<");
        });

        context.add("minimize", () => {
            tl.current = gsap.timeline({});

            tl.current
                .to(cursor.current, {
                    width: size,
                    height: size,
                    backgroundColor: color ?? "#F7FF07",
                    borderWidth: 0,
                    borderColor: color ?? "#F7FF07",
                    ease: "back.in",
                    onComplete: afterMinimizeCursor
                })
                .to(".cursor-text", {
                    ease: "back.in",
                    color: color ?? "#F7FF07",
                    onComplete: afterMinimizeCursor
                }, "<");
        });
        
        return () => window.removeEventListener("pointermove", context.onMove);
    }, { scope: cursor, dependencies: [color] });

    useGSAP(() => {
        if (expanded) {
            // gsap.to(cursor.current, {
            //     backgroundColor: "transparent",
            //     borderWidth: border,
            //     borderColor: color ?? "#F7FF07",
            //     ease: "back.out",
            // });

            gsapContext.current && gsapContext.current.expand();
        } else {
            // gsap.to(cursor.current, {
            //     backgroundColor: color ?? "#F7FF07",
            //     borderColor: color ?? "#F7FF07",
            //     ease: "back.in",
            // });

            gsapContext.current && gsapContext.current.minimize();
        }
    }, [expanded, color]);


    return (
        <Wrapper ref={cursor} />
    )
}

const Wrapper = forwardRef(function Wrapper (props: {}, ref: ForwardedRef<HTMLDivElement>) {
    const { text, textColor, color } = useCursor();

    return (
        <>
            <div ref={ref} className="invisible md:visible cursor overflow-hidden pointer-events-none touch-none">
                <div className="cursor-text -mt-2 flex items-center justify-center w-full h-full font-bold" style={{ color: textColor ?? "#F7FF07" }}>
                    {text}
                </div>
            </div>
        </>
    )
});

