import { Asset } from '@/models/asset';
import { FormData} from '@/modules/form';
import Config from './../config';

export function getAssetUrl(image: Asset) {
    const rootUrl = process.env.NODE_ENV === 'development' ? Config.media.development : Config.media.production;

    return `${rootUrl}${image.url}`
}

export function getFormUrl(form: FormData) {
    const rootUrl = process.env.NODE_ENV === 'development' ? Config.url.development : Config.url.production;
    
    return `${rootUrl}!/forms/${form.handle}`
}