import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/google/ga.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/google/gtm.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@vercel/speed-insights/dist/next/index.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/globals.css");
import(/* webpackMode: "eager" */ "/vercel/path0/src/lib/apollo-wrapper.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/lib/lenis.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/modules/cursor/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/modules/cursor/provider.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/modules/footer/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/modules/navigation/header/header.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/modules/navigation/menu/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/modules/navigation/provider.tsx")