'use client';

import { CSSProperties, useRef } from "react";
import { ButtonProps } from "./default";
import { useMagnetize } from "../../../hooks/useMagnetize";
import { colorToHex, useThemeColors } from "@/lib/colors";
import Link from "next/link";

interface Props extends ButtonProps {
    
}

export default function ButtonCircle({ theme_color = "tertiary", ...props }: Props) {

    const button = useRef<HTMLAnchorElement|HTMLButtonElement>();
    const setRef: React.RefCallback<HTMLAnchorElement | HTMLButtonElement> = (node) => {
        if(node !== null) {
            button.current = node
        }
    };

    useMagnetize(button, 1.5);

    const colors = useThemeColors(theme_color ?? "primary");

    const style = {
        "--background": colorToHex(props.background_color ?? colors.accent),
        "--color": colorToHex(props.background_color ?? colors.accent_text),
        "--hover-background": colorToHex(props.background_color ?? colors.text),
        "--hover-color": colorToHex(props.text_color ?? colors.background),
    } as CSSProperties;

    const componentProps = {
        className: `button relative rounded-full w-36 h-36 text-xl flex items-center justify-center font-bold leading-none ${props.className ?? ''}`,
        style: style,
        attributes: props.attributes
    }

    return (
        <>
            {
                props.href && <Link ref={setRef} href={props.href} target={props.target ?? "_self"}  {...componentProps}>
                    {props.label}
                </Link>
            }
            {
                !props.href && <button ref={setRef} onClick={props.onClick} disabled={props.disabled} {...componentProps}>
                    {props.label}
                </button>
            }
        </>
    )
}