import Image from "next/image";
import { Heading } from "../../../components/core/heading";

import { Service } from "@/models/services";
import ConditionalLink from "@/components/core/link";
import { ThemeColorSet } from "@/lib/colors";

interface Props {
    number: number,
    service: Service,
    open_page: boolean
    colors: ThemeColorSet
}

export default function ServiceTile({ colors, ...props}: Props) {

    return(
        <div className="flex w-full py-16 justify-between items-center">
            <div className="grow space-y-2">
                <Heading as="h3" size="6xl" weight="bold" color={colors.text}>{props.service.title}</Heading>
                <div className={`flex flex-col sm:flex-row flex-wrap sm:space-x-2 text-${colors.text} text-2xl`}>
                    {
                        props.service.services.map((service, index) => {
                            return(
                                <span key={index}>
                                    {index > 0 &&
                                        <span className={`hidden sm:inline text-${colors.accent} font-bold mr-2`}>/</span>
                                    }
                                    <>{service}</>
                                </span>
                            )
                        })
                    }
                </div>
            </div>
            <div className="space-x-4 items-center order-first md:order-none pr-8 md:pr-0 hidden sm:flex">
                <Heading size="8xl" weight="bold" color={colors.text}>0{props.number}</Heading>
            </div>
        </div>
    )
}