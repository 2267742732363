'use client';

import ScrollingHeading from "@/components/core/heading/scrolling";
import { useRef } from "react";
import Container from "@/components/core/container";
import Section from "@/components/core/section";


interface Props {
  slogan: string
}

export default function CaseStudySlogan(props: Props) {
  
  const container = useRef<HTMLDivElement>(null);

  return (
    <Section ref={container}>
      <Container width="fluid" center={true} className="py-8">
        <ScrollingHeading color="primary" transform="uppercase" size="20xl" speed="medium">{props.slogan}</ScrollingHeading>
      </Container>
    </Section>
  )
}