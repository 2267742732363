'use client';

import { Heading } from "../../../components/core/heading";
import Text from "../../../components/core/text";

import { Testimonial } from "@/models/testimonials";
import { colorToHex, ThemeColorSet } from "@/lib/colors";
import { useState } from "react";
import Link from "next/link";

interface Props {
    colors: ThemeColorSet,
    testimonial: Testimonial,
}

export default function TestimonialCard({ colors, ...props}: Props) {

    const [expand, setExpand] = useState(false);

    return(
        <div className={`testimonial px-4 md:px-0 flex flex-col w-full min-h-[600px] items-center`}>
            <blockquote className={`flex w-full py-16 px-4 md:px-8 rounded-lg space-x-4`}>
                <Heading size="11xl">&#8220;</Heading>
                <div className="space-y-4">
                    {!expand && props.testimonial.excerpt && props.testimonial.excerpt.length && <div>
                        <Text size="2xl" leading="snug" content={props.testimonial.excerpt} />
                    </div>}
                    {(expand || !props.testimonial.excerpt || !props.testimonial.excerpt.length) &&
                        <div>
                            <Text size="2xl" leading="snug" content={props.testimonial.content} />
                        </div>
                    }
                    <div className="testimonial-actions flex justify-start">
                        <div className="space-x-2">
                            {props.testimonial.project && <Link href={props.testimonial.project.url} className={`p-2 rounded-md bg-${colors.background} text-xs text-${colors.text}`}>View Project</Link>}
                            {props.testimonial.excerpt && props.testimonial.excerpt.length && <button onClick={() => setExpand(!expand)} className={`p-2 rounded-md bg-${colors.background} text-xs text-${colors.text}`}>Read {expand ? 'Less' : 'More'}</button>
                            }
                        </div>
                    </div>
                </div>
                <span className="arrow"></span>
            </blockquote>
            <div className="grow"></div>
            <div className="shrink-0 text-center mt-8">
                <Heading size="4xl" color={colors.text}>{props.testimonial.title}</Heading>
                <Text family="display" size="2xl" color={colors.text}>{props.testimonial.company}</Text>
            </div>
        </div>
    )
}