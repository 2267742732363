'use client';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useRef } from "react";
import { Heading, StylizedHeading } from "@/components/core/heading";
import Text from "@/components/core/text";
import Container from "@/components/core/container";
import Section from "@/components/core/section";
import { AssetImage } from "@/models/asset";
import { useThemeColors } from "@/lib/colors";
import Image from "next/image";
import { useGSAP } from "@gsap/react";
import { getAssetUrl } from "@/lib/cms";
import Link from "next/link";

gsap.registerPlugin(ScrollTrigger);


interface Props {
    year: string,
    name: string,
    description: string,
    image: AssetImage | null,
    website: string,
    facebook: string,
    instagram: string,
    background_color: string,
}

export default function NonProfitFeatured({ background_color, ...props}: Props) {

    const container = useRef<HTMLDivElement>(null);
    const image = useRef<HTMLImageElement>(null);
    const content = useRef<HTMLDivElement>(null);

    const tl = useRef<gsap.core.Timeline>();

    const colors = useThemeColors(background_color);

    const website = props.website ? props.website.replace(/(^\w+:|^)\/\//, '') : null;

    useGSAP(() => {
        tl.current = gsap.timeline({
            scrollTrigger: {
                trigger: container.current,
                start: "top 50%",
            }
        });

        gsap.set(container.current, { autoAlpha: 0 })

        content.current?.children && gsap.set(content.current?.children, { autoAlpha: 0, y: 200 });

        tl.current
            .to(container.current, { autoAlpha: 1, duration: .2 });

        if (image.current) {
            const imageStartX = -100;
            gsap.set(image.current, { autoAlpha: 0, x: imageStartX })

            tl.current
                .to(image.current, { autoAlpha: 1, x: 0, duration: .5, stagger: .5 }, "<");
        }

        content.current?.children && tl.current
            .to(content.current?.children, { autoAlpha: 1, y: 0, stagger: .7 });
    }, { scope: container, dependencies: [] });

    return (
        <Section ref={container} background_color={colors.background} className="py-24 space-y-8">
            <Container className={`flex flex-col space-y-8`}>
                <StylizedHeading as="h2" size="3xl" color={colors.accent} content={`${props.year} Honoree`} />
                <div className="max-w-2xl">
                    <Heading as="h3" size="8xl" leading="tight" transform="uppercase" content={props.name} color={colors.text}></Heading>
                </div>
            </Container>
            <Container className={`flex flex-col sm:flex-row space-y-8 sm:space-y-0 items-center justify-center gap-8`}>
                <div className={`image-container flex items-center w-full sm:w-1/2 order-first sm:order-none`}>
                    <div className="relative z-50 pt-[100%] w-full">
                        {props.image &&
                            <Image
                                ref={image}
                                className="rounded-xl max-w-full max-h-full w-full h-full absolute top-0 left-0"
                                src={getAssetUrl(props.image)}
                                width={props.image.width}
                                height={props.image.height}
                                alt={props.image.alt ?? `Image for ${props.name}`}
                                style={{
                                    objectFit: "cover",
                                    objectPosition: props.image.focus_css,
                                }}
                            />
                        }
                    </div>
                </div>
                <div ref={content} className="flex flex-col justify-center w-full md:w-1/2 space-y-4 sm:p-8 mt-8 sm:mt-0">
                    <Text color={colors.text} content={props.description} />
                    <div className="nonprofit-connect flex space-x-2 md:space-x-4 text-base md:text-xl">
                        {props.website && <Link href={props.website} className={`px-2 md:px-4 h-9 md:h-12 flex shrink-0 items-center bg-${colors.accent} text-${colors.accent_text} rounded-md`} target="_blank">{website}</Link>}
                        {props.facebook && <Link href={props.facebook} className={`w-9 h-9 md:w-12 md:h-12 text-base md:text-2xl flex shrink-0 items-center justify-center bg-${colors.accent} text-${colors.accent_text} rounded-full`} target="_blank" aria-label="Facebook"><i className="fa-brands fa-facebook" aria-hidden={true}></i></Link>}
                        {props.instagram && <Link href={props.instagram} className={`w-9 h-9 md:w-12 md:h-12 text-base md:text-2xl flex shrink-0 items-center justify-center bg-${colors.accent} text-${colors.accent_text} rounded-full`} target="_blank" aria-label="Instagram"><i className="fa-brands fa-instagram" aria-hidden={true}></i></Link>}
                    </div>
                </div>
            </Container>
        </Section>
    );
}