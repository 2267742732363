'use client';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect, useRef } from "react";
import Container from "@/components/core/container";
import Section from "@/components/core/section";
import Image from "next/image";
import { AssetImage } from "@/models/asset";
import { getAssetUrl } from "@/lib/cms";
import { Project } from "@/models/project";
import useNextBlurhash from "use-next-blurhash";
import { useGSAP } from "@gsap/react";

gsap.registerPlugin(ScrollTrigger);

interface Props {
    page: Project,
    columns?: "auto" | "2" | "3" | "4",
    section_width: "container" | "xs" | "sm" | "md" | "lg" | "xl" | "2xl" | "3xl" | "4xl" | "5xl" | "6xl" | "7xl" | "fluid" | undefined,
    images: AssetImage[]
}

export default function CaseStudyImages(props: Props) {
    
    const tl = useRef<gsap.core.Timeline>();
    const container = useRef<HTMLDivElement>(null);
    const images = useRef<HTMLDivElement>(null);

    const grid = {
        "1": "grid-cols-1",
        "2": "grid-cols-1 sm:grid-cols-2",
        "3": "grid-cols-1 sm:grid-cols-2 md:grid-cols-3",
        "4": "grid-cols-1 sm:grid-cols-2 md:grid-cols-4",
    }

    const getGridCSS = (images: AssetImage[]) => {
        
        if (props.columns && props.columns != "auto") {
            return grid[props.columns];
        }

        if ((images.length % 4) == 0) {
            return grid[4];
        }

        if ((images.length % 3) == 0) {
            return grid[3];
        }

        if ((images.length % 2) == 0) {
            return grid[2];
        }

        return grid[1];
    }

    useGSAP((context) => {

        tl.current = gsap.timeline({
            scrollTrigger: {
                trigger: container.current,
                start: "top 50%"
            }
        });

        gsap.set(images.current!.children, { autoAlpha: 0, y: 200 });

        tl.current
            .to(images.current!.children, { autoAlpha: 1, y: 0, stagger: .2 });

    }, { scope: container, dependencies: [] });

    return (
        <Section ref={container} className="case-study-image">
            <Container width={props.section_width} ref={images} className={`grid ${getGridCSS(props.images)} gap-4`}>
                {props.images.map((image, index) =>
                    <ProjectImage key={image.id} image={image}/>
                )}
            </Container>
        </Section>
    );
}

interface ProjectImageProps {
    image: AssetImage
}

function ProjectImage({ image }: ProjectImageProps) {
    const [blurDataUrl] = useNextBlurhash(image?.blurhash ?? "LEHLh[WB2yk8pyoJadR*.7kCMdnj");


    return (
        <Image
            src={getAssetUrl(image)}
            width={image.width}
            height={image.height}
            alt={image.alt ?? ""}
            placeholder={`${blurDataUrl ? 'blur' : 'empty'}`}
            blurDataURL={blurDataUrl}
            className="rounded-lg"
            style={{
                objectFit: "cover",
                objectPosition: image.focus_css,
            }} />
    )
}
