'use client';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useRef } from "react";
import { Button } from "@/components/core/button";
import { Heading, StylizedHeading } from "@/components/core/heading";
import Text from "@/components/core/text";
import Container from "@/components/core/container";
import Section from "@/components/core/section";
import { AssetImage } from "@/models/asset";
import { NonProfit } from "@/models/non_profits";
import { useThemeColors } from "@/lib/colors";

gsap.registerPlugin(ScrollTrigger);

interface Data {
    collections: {
        non_profits: NonProfit[]
    },
    pages: {
        support_local: {
            id: string,
            url: string
        }
    }
}

interface Props {
    background_image: AssetImage | null,
    background_color: string,
    data: Data
}

export default function SupportLocalCTA({ background_color, data, ...props}: Props) {
    const content = useRef<HTMLDivElement>(null);
    const featured = data.collections.non_profits[0] ?? null;

    const colors = useThemeColors(background_color);

    if (!featured) {
        return (<></>)
    }

    return (
        <Section background_color={background_color} background_image_opacity={20} background_image={featured.image} overlay_color={colors.background} overlay_gradient={`bg-gradient-to-t from-${colors.background}`}>
            <Container className={`flex items-end md:items-center py-32`}>
                <div ref={content} className="flex flex-col justify-center w-full md:w-1/2 space-y-4 sm:p-8 mt-8 sm:mt-0">
                    { featured && <>
                        <StylizedHeading as="h2" size="3xl" color={colors.accent} content="Featured Non-Profit" />
                        <Heading as="h3" size="8xl" leading="tight" transform="uppercase" content={featured.title} color={colors.text} className="text-left"></Heading>
                        <Text color={colors.text} content={featured.blurb}/>
                        <Text color={colors.text} content={`As part of our Strengthening Communities initiative, we aim to raise awareness and donate to local nonprofits dedicated to impacting our community and proud to feature ${featured.title}.`} />
                        <div>
                            <Button label="Learn More & Support Local" theme_color="primary" href={data.pages.support_local.url} />
                        </div>
                    </>}
                    {!featured && <>
                        <Heading as="h2" leading="tight" transform="uppercase" content="Support Local" color={colors.text} className="text-center md:text-left"></Heading>
                        <Text color={colors.text} content="As part of our Strengthening Communities initiative, we aim to raise awareness and donate to local nonprofits dedicated to impacting our community, with a focus on serving youth, the homeless, entrepreneurs, and those who are imprisoned." />
                        <div>
                            <Button label="Learn More" theme_color={background_color} href={data.pages.support_local.url} />
                        </div>
                    </>}
                </div>
            </Container>
        </Section>
    );
}