'use client';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useRef } from "react";
import { ButtonCircle } from "@/components/core/button";
import { Heading } from "@/components/core/heading";
import Container from "@/components/core/container";
import Section from "@/components/core/section";
import { useThemeColors } from "@/lib/colors";

gsap.registerPlugin(ScrollTrigger);

interface Data {
    pages: {
        start: {
            id: string,
            url: string
        },
        contact: {
            id: string,
            url: string
        }
    }
}

interface Props {
    title?: string,
    subtitle?: string,
    button?: string,
    open_popup?: boolean,
    background_colors?: string,
    data: Data
}

export default function StartCTA({ data, background_colors = "white", open_popup  = true, ...props}: Props) {
    const title = useRef<HTMLDivElement>(null);

    const colors = useThemeColors(background_colors);

    return (
        <Section background_color={colors.background}>
            <Container className="flex flex-col space-y-4 md:flex-row md:space-y-0 items-center justify-between py-32">
                <div>
                    <Heading as="h1" size="12xl" leading="tight" transform="uppercase" content={props.title ?? "Creativity Awaits"} className="text-center md:text-left text-8xl" color={colors.text}></Heading>
                    <Heading size="6xl" weight="normal" className="inline-block w-full text-center md:text-left" color={colors.text}>{props.subtitle ?? "Are you thinking what we are thinking?"}</Heading>
                </div>
                <div>
                    <ButtonCircle magnetic={true} theme_color={colors.background} label={props.button ?? "Let's Talk"} href="/start"/>
                </div>
            </Container>
        </Section>
    );
}