'use client';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useRef } from "react";
import { Heading, StylizedHeading } from "@/components/core/heading";
import Text from "@/components/core/text";
import Container from "@/components/core/container";
import Section from "@/components/core/section";
import { AssetImage } from "@/models/asset";
import { NonProfit } from "@/models/non_profits";
import { useThemeColors } from "@/lib/colors";
import { useGSAP } from "@gsap/react";
import Card from "./card";

gsap.registerPlugin(ScrollTrigger);

interface Data {
    collections: {
        non_profits: NonProfit[]
    }
}

interface Props {
    data: Data
}

export default function NonProfitList({ data, ...props}: Props) {
    
    const container = useRef<HTMLDivElement>(null);
    const title = useRef<HTMLHeadingElement>(null);

    const items = useRef<HTMLDivElement>(null);

    const tl = useRef<gsap.core.Timeline>();

    const colors = useThemeColors("white");


    useGSAP(() => {
        tl.current = gsap.timeline({
            scrollTrigger: {
                trigger: container.current,
                start: "top 80%"
            }
        });

        gsap.set(title.current, { autoAlpha: 0, y: 200 });
        gsap.set(items.current!.children, { autoAlpha: 0, y: 200 });

        tl.current
            .to(title.current, { autoAlpha: 1, y: 0 })
            .to(items.current!.children, { autoAlpha: 1, y: 0, stagger: .1 });
    }, { scope: container, dependencies: [] });


    return (
        <Section ref={container} className="py-32 space-y-8">
            <Container ref={title}>
                <div className="max-w-xl space-y-4">
                    <StylizedHeading as="h2" color={colors.accent} content="Non Profits we support" />
                    <Text size="2xl" color={colors.text}>
                        As part of our Strengthening Communities initiative, we aim to raise awareness and donate to local nonprofits dedicated to impacting our community, with a focus on serving youth, the homeless, entrepreneurs, and those who are imprisoned. Join us in our mission by donating to these causes, volunteering your time, or spreading the word. Together, we can build a stronger community. 
                    </Text>
                </div>
            </Container>
            <Container ref={items} className="grid grid-cols-1 md:grid-cols-2 gap-8">
                <>
                    {data?.collections.non_profits.map((non_profits, index) => <Card key={non_profits.id} number={index + 1} non_profit={non_profits} colors={colors} />)}
                </>
            </Container>
        </Section>
    );
}