import { MouseEventHandler, RefObject, useCallback, useEffect, useRef, useState } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { NavigationLink } from "@/models/navigation";
import { useGSAP } from "@gsap/react";
import { NavigationState, useNavigation } from "../provider";

gsap.registerPlugin(ScrollTrigger);

export function useInfiniteMenu(
        navigation: RefObject<HTMLUListElement>,
        links?: NavigationLink[],
    ) {

    const { visible } = useNavigation();

    const animationRequest = useRef<number|undefined>();

    const [resetAnimation, setResetAnimation] = useState(false);

    const [clones, setClones] = useState<NavigationLink[]>([]);
    const [itemOrder, setItemOrder] = useState<HTMLLIElement[]>([]);

    // const [scrollY, setScrollY] = useState<number>(0);
    // const [y, setY] = useState<number>(0);

    useEffect(() => {
        if (!navigation.current || !links) return;

        let observer: MutationObserver;
        const currentNavigation = navigation.current;

        let isDragging = false;

        let wrapperHeight = 0,
            itemHeight = 0,
            totalClones = 0,
            scrollY = 0,
            y = 0,
            scrollSpeed = 0,
            oldScrollY = 0;

        let touchStart = 0, touchY = 0;

        const cloneItems = () => {
            const items = currentNavigation.childNodes;
            
            if(items[0]) {
                itemHeight = (items[0] as HTMLLIElement).offsetHeight;
            } else {
                itemHeight = 60;
            }

            // Add clones
            totalClones = 0;
            let clones: NavigationLink[] = [];
            
            links.map(clone => {
                clones.push(clone);
                ++totalClones;
            });

            setClones(clones);

            wrapperHeight = (links.length + totalClones) * itemHeight;
        }

        const resize = () => {
            y = 0;
            scrollY = 120;

            cloneItems();
            go(y);
        }

        
        const onMouseWheel = (e: WheelEvent) => {
            scrollY -= e.deltaY;
        }

        const getY = (e: TouchEvent | MouseEvent) => {
            if(e instanceof TouchEvent) {
                return e.touches[0].clientY;
            }

            return e.clientY;
        }

        const onTouchStart = (e: TouchEvent | MouseEvent) => {
            touchStart = getY(e);
            isDragging = true;
        }

        const onTouchMove = (e: TouchEvent | MouseEvent) => {
            if(!isDragging) return;

            touchY = getY(e);
            scrollY += (touchY - touchStart) * 8;
            touchStart = touchY;
        }

        const onTouchend = () => {
            isDragging = false;
        }

        const go = (scroll: number) => {
            const items = currentNavigation.childNodes;

            gsap.set(items, {
                y: (i) => i * itemHeight + scroll,
                modifiers: {
                    y: (y) => {
                        const s = gsap.utils.wrap(
                            -itemHeight,
                            wrapperHeight - itemHeight,
                            parseInt(y)
                        )
                        return `${s}px`
                    }
                }
            });
        }

        const render = () => {
            animationRequest.current = requestAnimationFrame(render);
            animate();
        }

        const animate = () => {
            y = lerp(y * 0.9, scrollY, 0.05);

            go(y);

            scrollSpeed = y - oldScrollY;
            oldScrollY = y;

            const items = currentNavigation.childNodes as NodeListOf<HTMLLIElement>;

            gsap.to(items, {
                scale: 1 - Math.min(100, Math.abs(scrollSpeed)) * 0.002,
                rotate: scrollSpeed * 0.05
            });

            const sorted = sort(items);

            setItemOrder(sorted)
        }

        const reset = () => {
            y = 0;
            scrollY = 120;
            go(y);
            animate();
        }

        const init = () => {
            cloneItems();

            observer = new MutationObserver((mutationsList) => {
                for (let mutation of mutationsList) {
                    if (mutation.type === "childList") {
                        reset();
                    }
                }
            });

            observer.observe(currentNavigation, { childList: true });

            window.addEventListener('resize', resize);

            currentNavigation.addEventListener('wheel', onMouseWheel);
            currentNavigation.addEventListener('touchstart', onTouchStart);
            currentNavigation.addEventListener('touchmove', onTouchMove);
            currentNavigation.addEventListener('touchend', onTouchend);
            currentNavigation.addEventListener('mousedown', onTouchStart);
            currentNavigation.addEventListener('mousemove', onTouchMove);
            currentNavigation.addEventListener('mouseleave', onTouchend);
            currentNavigation.addEventListener('mouseup', onTouchend);
        }

        switch(visible) {
            case NavigationState.OPENED:
                render();
                break;
            case NavigationState.CLOSED:
                cloneItems();
                reset();
                break;
        }

        init();

        return () => {
            if (animationRequest.current) {
                cancelAnimationFrame(animationRequest.current);
            }

            observer.disconnect();

            window.removeEventListener('resize', resize);
            currentNavigation.removeEventListener('wheel', onMouseWheel);
            currentNavigation.removeEventListener('touchstart', onTouchStart);
            currentNavigation.removeEventListener('touchmove', onTouchMove);
            currentNavigation.removeEventListener('touchend', onTouchend);
            currentNavigation.removeEventListener('mousedown', onTouchStart);
            currentNavigation.removeEventListener('mousemove', onTouchMove);
            currentNavigation.removeEventListener('mouseleave', onTouchend);
            currentNavigation.removeEventListener('mouseup', onTouchend);
        };
    }, [navigation, links, visible]);

    const cleanup = () => {
        if (animationRequest.current) {
            cancelAnimationFrame(animationRequest.current);
        }
    }

    return { clones, order: itemOrder, reset: cleanup }
}

const lerp = (number0: number, number1: number, time: number) => {
    return number0 * (1 - time) + number1 * time
}


const sort = (items: NodeListOf<HTMLLIElement>) => {

    let itemsArray = Array.from(items);

    itemsArray.sort((item1, item2) => {
        const translateForItem1 = getTranslateValues(item1 as HTMLLIElement);
        const translateForItem2 = getTranslateValues(item2 as HTMLLIElement);

        if (translateForItem1.y < translateForItem2.y) {
            return -1;
        }
        if (translateForItem1.y > translateForItem2.y) {
            return 1;
        }

        return 0;
    });

    return itemsArray;
}

const getTranslateValues = (element: HTMLLIElement) => {
    const style = window.getComputedStyle(element)
    const matrix = style['transform']

    // No transform property. Simply return 0 values.
    if (matrix === 'none' || typeof matrix === 'undefined' || !matrix) {
        return {
            x: 0,
            y: 0,
            z: 0,
        }
    }

    // Can either be 2d or 3d transform
    const matrixType = matrix.includes('3d') ? '3d' : '2d';

    const matches = matrix.match(/matrix.*\((.+)\)/);

    if (!matches || matches.length < 1) return {
        x: 0,
        y: 0,
        z: 0
    }

    const matrixValues = matches[1].split(', ')

    // 2d matrices have 6 values
    // Last 2 values are X and Y.
    // 2d matrices does not have Z value.
    if (matrixType === '2d') {
        return {
            x: parseInt(matrixValues[4]),
            y: parseInt(matrixValues[5]),
            z: 0,
        }
    }

    // 3d matrices have 16 values
    // The 13th, 14th, and 15th values are X, Y, and Z
    if (matrixType === '3d') {
        return {
            x: parseInt(matrixValues[12]),
            y: parseInt(matrixValues[13]),
            z: parseInt(matrixValues[14]),
        }
    };

    return {
        x: 0,
        y: 0,
        z: 0
    }
}