'use client';

import Image from "next/image";
import { Heading } from "../../../components/core/heading";
import Text from "../../../components/core/text";

import { ThemeColorSet } from "@/lib/colors";
import { Process } from "@/models/processes";

interface Props {
    number: number,
    process: Process,
    colors: ThemeColorSet
}

export default function ProcessCard({ colors, ...props}: Props) {

    return(
        <div className={`flex w-full py-16 px-8 justify-between rounded-lg bg-${colors.accent}`}>
            <div className="flex w-full justify-between">
                <div className="space-y-4 md:max-w-lg">
                    <div>
                        <Heading as="h3" size="6xl" weight="bold" color={colors.accent_text}>{props.process.title}</Heading>
                        <Text color={colors.accent_text} content={props.process.summary} />
                    </div>
                    <Text color={colors.accent_text} content={props.process.content} />
                </div>
                <Heading className="order-first md:order-2 pr-8 md:pr-0" size="8xl" weight="bold" color={colors.background}>0{props.number}</Heading>
            </div>
        </div>
    )
}