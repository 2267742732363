'use client';

import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { SlowMo } from "gsap/EasePack";
import { useRef } from "react";

import Container from "../../../components/core/container";
import Section from "../../../components/core/section";
import Text from "@/components/core/text";
import { StylizedHeading } from "@/components/core/heading";
import ProcessTile from "./tile";
import ProcessCard from "./card";
import { useThemeColors } from "@/lib/colors";
import { Processes } from "@/models/processes";
import { useCursorEffects } from "@/modules/cursor/useCursorEffects";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(SlowMo);

interface Props {
    title: string,
    display: "list" | "grid",
    background_color: string,
    open_page: boolean,
    show_title: boolean,
    data: Processes
}


export default function ProcessesList({ data, background_color, display, ...props}: Props) {

    const container = useRef<HTMLDivElement>(null);
    const title = useRef<HTMLHeadingElement>(null);

    const items = useRef<HTMLDivElement>(null);

    const tl = useRef<gsap.core.Timeline>();

    const colors = useThemeColors(background_color);

    useCursorEffects(container, { color: colors.cursor });

    useGSAP(() => {
        tl.current = gsap.timeline({
            scrollTrigger: {
                trigger: container.current,
                start: "top 50%"
            }
        });

        gsap.set(title.current, { autoAlpha: 0, y: 200 });
        gsap.set(items.current!.children, { autoAlpha: 0, y: 200 });

        tl.current
            .to(title.current, { autoAlpha: 1, y: 0 })
            .to(items.current!.children, { autoAlpha: 1, y: 0, stagger: .1 });
    }, { scope: container, dependencies: [] });

    const Component = display == "grid" ? ProcessCard : ProcessTile;

    return (
        <Section ref={container} background_color={background_color} className={`space-y-16 py-16`}>
            <Container ref={title}>
                <div className="max-w-xl space-y-4">
                    <StylizedHeading as="h2" color={colors.accent} content="Process" />
                    <Text size="2xl" color={colors.text} >
                        From ideas to the captivating reality, this is our streamlined process to understand your vision and challenges, thoughtfully select ideas for a clear path forward, and infuse creativity to craft compelling ideas. With every pixel, line of code, and strategic move ideas are ready to take on the world.
                    </Text>
                </div>
            </Container>
            <Container ref={items} className={`${display == "grid" ? 'grid grid-cols-1 md:grid-cols-2 gap-8' : 'space-y-4'}`}>
                <>
                    {data?.collections.processes.entries.map((process, index) => <Component key={process.entry.id} number={index + 1} process={process.entry} colors={colors}/>)}
                </>
            </Container>
        </Section>
    )
}

