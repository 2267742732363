'use client';

import React from 'react';
import { forwardRef } from 'react';
import BaseText, { BaseTextProps } from '../text/base';

export interface HeadingProps extends BaseTextProps {
    as?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "span" | "div"
}
const Heading = forwardRef(function Heading(props: HeadingProps, ref) {
    const {
        as = "span",
        family = "display",
        size = "7xl",
        weight = "bold",
        leading = "tight",
        whiteSpace = "pre-line",
    } = props;

    return (
        <BaseText ref={ref}
            className="font-display"
            as={as}
            family={family}
            size={size}
            weight={weight}
            leading={leading}
            whiteSpace={whiteSpace}
            {...props}>
            {props.children}
        </BaseText>
    )
});

export default Heading;