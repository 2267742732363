'use client';

import React, { useState, useEffect, useContext, createContext, ReactNode } from 'react';

export enum NavigationState {
  CLOSED = "CLOSED",
  CLOSING = "CLOSING",
  OPENING = "OPENING",
  OPENED = "OPENED",
}

const DEFAULT_VISIBLE = NavigationState.CLOSED;

interface NavigationContextInterface {
  visible: NavigationState,
  toggle: (option?: NavigationState) => void,
  setState: (state: NavigationState) => void,
  transitioning: boolean,
  transition: (option: boolean) => void,
}

const defaultState = {
  visible: DEFAULT_VISIBLE,
  toggle: (option?: NavigationState) => {},
  setState: (state: NavigationState) => { },
  transitioning: false,
  transition: (option: boolean) => {}
};

const NavigationContext = createContext<NavigationContextInterface>(defaultState);

interface NavigationProviderInterface {
  children: ReactNode,
}

export function NavigationProvider({ children }: NavigationProviderInterface) {
  const navigation = useProvideNavigation();
  return <NavigationContext.Provider value={navigation}>{children}</NavigationContext.Provider>;
}

export const useNavigation = () => {
  return useContext(NavigationContext);
};

function useProvideNavigation(): NavigationContextInterface {
  const [visible, setVisible] = useState<NavigationState>(DEFAULT_VISIBLE);
  const [transitioning, setTransitioning] = useState(false);

  const toggle = () => {
    if (transitioning) return;

    let option = isOpen() ? NavigationState.CLOSING : NavigationState.OPENING;
    setState(option);
  };

  const setState = (state: NavigationState) => {
    
    if([NavigationState.OPENING, NavigationState.CLOSING].includes(state)) {
      transition(true);
    } else {
      transition(false);
    }
    
    setVisible(state);
  };

  const transition = (option: boolean) => {
    setTransitioning(option);
  };

  const isOpen = () => {
    return [NavigationState.OPENED].includes(visible);
  }

  return {
    visible,
    toggle,
    setState,
    transitioning,
    transition
  };
}