'use client';

import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useRef } from 'react';
import Section from "../core/section";
import Container from "../core/container";
import { Heading } from "../core/heading";
import Text from "../core/text";
import { useThemeColors } from "@/lib/colors";
import { useCursorEffects } from "@/modules/cursor/useCursorEffects";

gsap.registerPlugin(ScrollTrigger);

interface Props {
  title: string | null,
  title_size: "default" | "xs" | "sm" | "base" | "lg" | "xl" | "2xl" | "3xl" | "4xl" | "5xl" | "6xl" | "7xl" | "8xl" | "9xl" | "title",
  content: string | null,
  content_size: "default" | "xs" | "sm" | "base" | "lg" | "xl" | "2xl" | "3xl" | "4xl" | "5xl" | "6xl" | "7xl" | "8xl" | "9xl" | "title",
  background_color: string,
  width: "sm" | "md" | "lg" | "xl" | "2xl",
  height: string,
}

export default function DefaultContent({width = "2xl", ...props}: Props) {
  
  const container = useRef<HTMLDivElement>(null);
  const content = useRef<HTMLDivElement>(null);

  const tl = useRef<gsap.core.Timeline>();

  const colors = useThemeColors(props.background_color);
  
  useCursorEffects(container, { color: colors.cursor });

  useGSAP(() => {

    tl.current = gsap.timeline({
      scrollTrigger: {
        trigger: content.current,
        start: "top 75%",
      }
    });

    gsap.set(content.current, { autoAlpha: 0, y: 200 })

    tl.current
      .to(content.current, { autoAlpha: 1, y: 0})
  }, { scope: container, dependencies: [] });
  
  return (
    <Section ref={container} background_color={props.background_color}>
      <Container width={width} height={props.height} center={true} className={`${props.height == "auto" ? "py-32" : ""}`}>
        <div ref={content} className="text space-y-4">
          {props.title && props.title.length &&
            <Heading as="h2" color={colors.text} size={ props.title_size == 'default' ? "7xl" : props.title_size }>{props.title}</Heading>
          }
          <Text className="space-y-4" color={colors.text} size={ props.content_size == 'default' ? "2xl" : props.content_size } content={props.content} />
        </div>
      </Container>
    </Section>
  )
}