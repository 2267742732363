'use client';
import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect, useRef, useState } from "react";
import { useCursor } from "@/modules/cursor/provider";
import useLayoutEffect from "@/hooks/useIsomorphicLayoutEffect";
import { Project } from "@/models/project";
import Image from "next/image";
import { Heading } from "../../../components/core/heading";
import Text from "../../../components/core/text";
import { getAssetUrl } from "@/lib/cms";
import Link from "next/link";

gsap.registerPlugin(ScrollTrigger);

interface Props {
    project: Project,
    featured?: boolean
}

export default function ProjectCard(props: Props) {
    const container = useRef<HTMLAnchorElement>(null);
    const imageContainer = useRef<HTMLDivElement>(null);

    const gsapContext = useRef<gsap.Context>();
    const tl = useRef<gsap.core.Timeline>(gsap.timeline({ repeat: -1 }));
    const tl2 = useRef<gsap.core.Timeline>();

    const { expandCursor, minimizeCursor } = useCursor();

    useGSAP((context) => {
        gsapContext.current = context;

        context.add("setupSlideshow", () => {
            var duration = 0.3;

            tl.current = gsap.timeline({ repeat: -1, paused: true });

            const images: HTMLImageElement[] = gsap.utils.toArray(imageContainer.current!.children);

            for (var i = images.length - 2; i > -1; i--) {
                if (i != images.length - 1) {
                    tl.current.to(images[i + 1], { duration: duration, autoAlpha: 0 }, "<");
                }

                tl.current.to(images[i], { duration: duration, autoAlpha: 1 });

                if (i == 0) {
                    tl.current.to(images[i], { duration: duration, autoAlpha: 0 }, "<");
                    tl.current.to(images[images.length - 1], { duration: duration, autoAlpha: 1 }, "<");
                }
            }
        });

        context.add("start", () => {

            if (!tl.current) return;

            tl.current.timeScale(tl.current.timeScale() || 0.001).play()
        });

        context.add("stop", () => {
            if (!tl.current) return;

            tl.current.pause(0);
        });

        context.add("enter", () => {
            const image = ".card-image";

           // gsap.set(image, { clipPath: "inset(0px 15% round 20px)" })

            tl2.current = gsap.timeline({
                scrollTrigger: {
                    trigger: image,
                    start: "top 80%",
                    end: "bottom 20%",
                    scrub: true,
                }
            });

            tl2.current
                .from(image, { clipPath: "inset(0px 15% round 20px)" })
                .to(image, { clipPath: "inset(0px 0% round 0px)" });
        });

        const onMouseEnter = () => {
            gsapContext.current && gsapContext.current.start();
        }

        const onMouseLeave = () => {
            gsapContext.current && gsapContext.current.stop();
        }

        container.current?.addEventListener("mouseenter", onMouseEnter);
        container.current?.addEventListener("mouseleave", onMouseLeave);

        return () => {
            const cleanup = container.current;

            if(cleanup) {
                cleanup.removeEventListener("mouseenter", onMouseEnter);
                cleanup.removeEventListener("mouseleave", onMouseLeave)
            }
        }
    }, { scope: container, dependencies: [] });


    useEffect(() => {
        const onMouseEnter = () => {
            expandCursor("View", "blue", "yellow");
        }

        const onMouseMove = () => {
            expandCursor("View", "blue", "yellow");
        }

        const onMouseLeave = () => {
            minimizeCursor();
        }

        container.current?.addEventListener("mouseenter", onMouseEnter);
        container.current?.addEventListener("mousemove", onMouseMove);
        container.current?.addEventListener("mouseleave", onMouseLeave);

        const cleanup = container.current;

        return () => {
            if (cleanup) {
                cleanup.removeEventListener("mouseenter", onMouseEnter);
                cleanup.removeEventListener("mousemove", onMouseMove);
                cleanup.removeEventListener("mouseleave", onMouseLeave)
            }
        }
    }, [expandCursor, minimizeCursor]);

    useLayoutEffect(() => {
        if (props.featured) {
            gsapContext.current && gsapContext.current.enter();
        } else {
            gsapContext.current && gsapContext.current.setupSlideshow();
        }
    }, [props.featured]);

    return (
        <Link ref={container} href={props.project.url} className="relative space-y-4">
            <div className={`card-image`}>
                <div className={`relative bg-primary media-fill overflow-hidden ${props.featured ? 'h-screen' : 'rounded-xl pt-[100%] aspect-square'}`}>
                    <div ref={imageContainer} className="absolute top-0 left-0 w-full h-full">
                        {[...props.project.cover_images].reverse().map((image, index) =>
                            <Image
                                key={index}
                                src={getAssetUrl(image)}
                                width={image.width}
                                height={image.height}
                                alt={image.alt ?? `Sample work for ${props.project.client.title}`}
                                className="absolute top-0 left-0 w-full h-full"
                                style={{
                                    objectFit: "cover",
                                    objectPosition: image.focus_css,
                                }}/>
                        )}
                    </div>
                    {!props.featured && <div className="absolute bottom-4 left-4 z-10 flex items-center flex-wrap gap-2 text-xs md:text-base text-white">
                        {props.project.services.map(service => <span key={service.id} className="px-2 py-1 rounded-md bg-primary">{service.title}</span>)}
                    </div>}
                    {props.featured && <div className="absolute top-4 left-4 z-10 flex items-center flex-wrap gap-2 text-base md:text-lg text-white max-w-lg">
                        {props.project.services.map(service => <span key={service.id} className="px-2 py-1 rounded-md bg-primary">{service.title}</span>)}
                    </div>}
                </div>
            </div>
            <div className={`${props.featured ? 'absolute bottom-8 left-8 bg-tertiary text-secondary py-4 px-8' : ''}`}>
                
                <Heading as="h3" size={props.featured ? '6xl' : '4xl'} weight="bold" leading="none">{props.project.project_title}</Heading>
                <Text size={props.featured ? '2xl' : 'xl'} leading="none">{props.project.client.title}</Text>
            </div>
        </Link>

    )
}