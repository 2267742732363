import { forwardRef } from 'react';
import React from "react";
import BaseText, { BaseTextProps } from "./base";

export interface TextProps extends BaseTextProps {
    as?: "p" | "div" | "label" | "span",
}

const Text =  forwardRef(function Text(props: TextProps, ref) {

    const {
        as = "div",
        size = "2xl",
    } = props;
        
    return (
        <BaseText ref={ref}
            as={as}
            size={size}
            {...props}>
            {props.children}
        </BaseText>
    )
});

export default Text;