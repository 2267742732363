import { DocumentNode, NoInfer, OperationVariables, QueryHookOptions, TypedDocumentNode, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";

export default function usePersistedQuery<T>(query: DocumentNode | TypedDocumentNode<T, OperationVariables>, options: QueryHookOptions<NoInfer<T>, OperationVariables> | undefined) {

    const [currentData, setCurrentData] = useState<T | undefined>();
    const { loading, error, data, refetch } = useQuery<T>(query, options);

    useEffect(() => {
        if (data) {
            setCurrentData(data);
        }
    }, [data]);

    return ({
        loading,
        error,
        data: currentData,
        refetch
    })

}