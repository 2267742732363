'use client';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect, useRef, useState } from "react";
import Container from "@/components/core/container";
import Section from "@/components/core/section";
import Text from "@/components/core/text";
import { Heading } from "@/components/core/heading";
import { Testimonial } from "@/models/testimonials";
import { useGSAP } from "@gsap/react";

gsap.registerPlugin(ScrollTrigger);

interface Props {
    testimonial?: Testimonial
}

export default function CaseStudyTestimonial(props: Props) {

    const tl = useRef<gsap.core.Timeline>();
    const container = useRef<HTMLDivElement>(null);

    const [expand, setExpand] = useState(false);

    useGSAP(() => {

        if (!container.current) return;

        tl.current = gsap.timeline();

        gsap.set(container.current!.children, { autoAlpha: 0, y: 200 });

        tl.current
            .to(container.current!.children, { autoAlpha: 1, y: 0, stagger: 0.1 });

    }, []);

    return (
        <>
            {props.testimonial &&
                <Section ref={container}>
                    <Container width="7xl" className="space-y-4">
                        <Heading as="h2" size="7xl" transform="uppercase" className="hidden">Words from our client</Heading>
                        <div className="space-y-8">
                            {(!expand && props.testimonial.excerpt) && <Text size="6xl" leading="snug" className="prose">{props.testimonial.excerpt.replace(/<p[^>]*>(.*?)<\/p>/ig, "$1")} <button onClick={() => setExpand(!expand)} className="text-primary text-xl">Read {expand ? 'Less' : 'More'}</button></Text>}
                            {(expand || !props.testimonial.excerpt) && <Text size="6xl" leading="snug" content={props.testimonial.content} className="prose" />}
                            <div className="leading-tight">
                                <Heading size="4xl" className="leading-tight">{props.testimonial.title}</Heading>
                                <Text family="display" size="xl">{props.testimonial.company}</Text>
                            </div>
                        </div>
                    </Container>
                </Section>
            }
        </>
    );
}