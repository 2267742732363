'use client';

import Image from "next/image";
import { Heading } from "../../../components/core/heading";
import Text from "../../../components/core/text";

import { ThemeColorSet, useThemeColors } from "@/lib/colors";
import { Process } from "@/models/processes";
import { useCursorEffects } from "@/modules/cursor/useCursorEffects";
import { useRef } from "react";

interface Props {
    number: number,
    process: Process,
    colors: ThemeColorSet
}

export default function ProcessCard({ colors, ...props}: Props) {

    const container = useRef<HTMLDivElement>(null);

    const themeColor = useThemeColors(colors.accent);
    useCursorEffects(container, { color: themeColor.cursor });
    
    return(
        <div ref={container} className={`flex w-full py-16 px-8 rounded-lg bg-${colors.accent}`}>
            <div className="space-y-4 flex-col">
                <Heading size="8xl" weight="bold" color={colors.background}>0{props.number}</Heading>
                <div>
                    <Heading as="h3" weight="bold" color={colors.accent_text}>{props.process.title}</Heading>
                    <Text color={colors.accent_text} content={props.process.summary} />
                </div>
                <Text color={colors.accent_text} content={props.process.content}/>
            </div>
        </div>
    )
}