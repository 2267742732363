'use client';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useRef } from "react";
import { ButtonCircle } from "../core/button";
import useLayoutEffect from "@/hooks/useIsomorphicLayoutEffect";
import { Heading } from "../core/heading";
import Container from "../core/container";
import Section from "../core/section";
import { useThemeColors } from "@/lib/colors";

gsap.registerPlugin(ScrollTrigger);

interface Props {
    title?: string,
    subtitle?: string,
    button?: string,
    button_link?: string | null,
    button_link_target?: "_self" | "_blank",
    open_contact_form?: boolean,
    background_colors?: string
}

export default function LargeCTA({ background_colors = "white", open_contact_form  = true, ...props}: Props) {
    const title = useRef<HTMLDivElement>(null);

    const colors = useThemeColors(background_colors);

    const onClick = () => {
        if (open_contact_form) {

       } else if(props.button_link) {
           window.open(props.button_link, props.button_link_target ?? "_self");
       }
    }

    return (
        <Section background_color={colors.background}>
            <Container className="flex flex-col space-y-4 md:flex-row md:space-y-0 items-center justify-between py-32">
                <div>
                    <Heading as="h1" size="12xl" leading="tight" transform="uppercase" content={props.title ?? "Creativity Awaits"} className="text-center md:text-left text-8xl" color={colors.text}></Heading>
                    <Heading size="6xl" weight="normal" className="inline-block w-full text-center md:text-left" color={colors.text}>{props.subtitle ?? "Are you thinking what we are thinking?"}</Heading>
                </div>
                <div>
                    <ButtonCircle magnetic={true} theme_color={colors.background} label={props.button ?? "Let's Talk"} onClick={onClick}/>
                </div>
            </Container>
        </Section>
    );
}