'use client';

import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { SlowMo } from "gsap/EasePack";
import moment from 'moment';
import { useRef, useState } from "react";

import Container from "../../../components/core/container";
import Section from "../../../components/core/section";
import { Heading } from "@/components/core/heading";
import Text from "@/components/core/text";

import Cal, { getCalApi } from "@calcom/embed-react";
import { useEffect } from "react";
import Form, { FormData, handleErrorResponse } from "@/modules/form";
import { Button } from "@/components/core/button";

import { getClient } from "@/lib/rest";
import { getFormUrl } from "@/lib/cms";

import { BookingSuccessfulEvent, BookingSuccessfulData, CALENDAR_THEME } from "@/models/cal";
import { Page } from "@/models/pages";
import { Tab, TabPanel, Tabs } from "@/components/core/tabs";
import { Testimonial } from "@/models/testimonials";
import { link } from "fs";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(SlowMo);

interface Data {
    collections: {
        consultations: Consultation[]
    },

    pages: {
        consulting: {
            id: string,
            url: string
        },
    },

    testimonials: {
        testimonials: Testimonial[]
    },
}

export interface Consultation extends Page {
    duration: number,
    price: string,
    calendar: string,
}

interface Props {
    title: string,
    form: FormData,
    examples: string[],
    data: Data
}

export default function ConsultationForm({data, ...props}: Props) {

    const container = useRef<HTMLDivElement>(null);
    const tl = useRef<gsap.core.Timeline>();

    const [testimonials, setTestimonials] = useState<Testimonial[]>([]);

    const [formData, setFormData] = useState<{ [key: string]: any }>({});
    const [formErrors, setFormErrors] = useState<{ [key: string]: any }>({});
    const [bookingData, setBookingData] = useState<BookingSuccessfulData |null>(null);

    const [step, setStep] = useState(1);
    const [enableScheduling, setEnableScheduling] = useState(true);
    const [disableSubmit, setDisableSubmit] = useState(false);

    const [validated, setValidated] = useState(false);
    const [scheduled, setScheduled] = useState(false);
    const [completed, setCompleted] = useState(false);
    const [processing, setProcessing] = useState(false);

    const [selectedConsultation, setSelectedConsultation] = useState<Consultation>(data.collections.consultations[0]);
    

    useEffect(() => {
        const testimonials = [...data.testimonials.testimonials.filter(testimonial => testimonial.feature)];
        const shuffleArray = (array: Testimonial[]) => {
            const shuffled = array.slice(); // Create a shallow copy of the original array
            for (let i = shuffled.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]]; // Swap elements
            }
            return shuffled;
        };

        // Shuffle the array when the component mounts
        setTestimonials(shuffleArray(testimonials).slice(0, 2));
    }, [data.testimonials.testimonials]);

    useGSAP(() => {
        tl.current = gsap.timeline({
            scrollTrigger: {
                trigger: container.current,
                start: "top 50%"
            }
        });

        gsap.set(container.current, { autoAlpha: 0, y: 200 });

        tl.current
            .to(container.current, { autoAlpha: 1, y: 0 })
    }, { scope: container, dependencies: [] });

    useEffect(() => {
        (async function () {

            const Cal = await getCalApi();

            Cal("ui", { 
                "theme": "light",
                "hideEventTypeDetails": false,
                "layout": "month_view",
                "cssVarsPerTheme": CALENDAR_THEME
            });

            Cal("on", {
                action: "linkFailed",
                callback: (e) => {
                    const { data } = e.detail;
                    console.error(data);
                }
            });

            Cal("on", {
                action: "linkReady",
                callback: (e) => {
                    const { data, namespace } = e.detail;
                    console.log(namespace, data);
                }
            });

            Cal("on", {
                action: "bookingSuccessful",
                callback: (e: any) => {
                    const { data: eventData } = e.detail as BookingSuccessfulEvent;

                    setBookingData(eventData);
                    
                    setFormData((formData) => {
                        return { ...formData, appointment: eventData.booking.uid };
                    });
                    setScheduled(true);
                }
            });
        })();
    }, []);


    const next = () => {
        setStep(2);
    }

    const submit = () => {
        setProcessing(true);
        setDisableSubmit(true);
        setFormErrors({});

        getClient()
            .post(`${getFormUrl(props.form)}`, formData)
            .then(() => {
                setDisableSubmit(false);
                setCompleted(true);
                setStep(3);
                setProcessing(false);
            })
            .catch(async (errorOrResponse: Error | Response) => {
                const errors = await handleErrorResponse(errorOrResponse);

                setDisableSubmit(false);
                setFormErrors(errors);
                setProcessing(false);
            })
    }

    const onFormChange = (values: { [key: string]: any}, validated: boolean) => {
        setFormData(values);
        setValidated(validated);
    }

    const onSchedulableChange = (selectedOption: any) => {
        const option = (selectedOption as { value: boolean, label: string }).value;
        setEnableScheduling(option);
    }

    return (
        <Section ref={container} className={`space-y-16 pt-32 pb-16 bg-light-gray`}>
            <Container className="block lg:flex gap-8 space-y-16 lg:space-y-0">
                <div className="space-y-4 w-full lg:w-2/6">
                    <div>
                        <Heading content={props.title} leading="tight" />
                        <Text content="These calls can be about whatever you want, but here are a few topics we can discuss." />
                        <div className="space-y-2 mt-4">
                            <ul className="list-disc ml-4">
                                {
                                    props.examples.map((example, index) => <li key={index}>
                                        <Text size="2xl">{example}</Text>
                                    </li>)
                                }
                            </ul>
                        </div>
                    </div>
                    <div>
                        <Heading content="Hear it from others:" size="3xl" leading="tight" />
                        <div className="space-y-4">
                            {testimonials.map(testimonial => <div key={testimonial.id}>
                                <Text><em>&quot;{testimonial.feature}&quot;</em> - <span className="text-primary">{testimonial.title}, {testimonial.company}</span></Text>
                                </div>)
                            }
                        </div>
                    </div>
                </div>
                <div className="form-wrapper w-full lg:w-4/6">
                    <Tabs className="form-tabs" >
                        <Tab id="panelInformation" selected={step == 1} disabled={completed} onClick={() => setStep(1)}>01 Information</Tab>
                        <Tab id="panelScheduling" selected={step == 2} disabled={!validated || completed} onClick={() => setStep(2)}>02 Schedule</Tab>
                        <Tab id="panelConfirmation" selected={step == 3} disabled={!completed} onClick={() => setStep(2)}>03 Confirmation</Tab>
                    </Tabs>
                    <TabPanel id="panelInformation" className="space-y-4" selected={step == 1}>
                        <div className="space-y-2">
                            <div className="form-field-label space-x-2">
                                <Text as="label" className="px-2" family="display" size="xl" weight="bold" content="Duration" attributes={{
                                    "id": "form-consulation"
                                }} />
                                <span className='bg-red-400 text-white text-xs p-1 rounded-sm leading-none'>Required</span>
                            </div>
                            
                            <div className="flex flex-wrap" role="radiogroup" aria-required aria-labelledby="form-consulation">

                                {data.collections.consultations.map(consultation => <button
                                    key={consultation.id}
                                    onClick={() => setSelectedConsultation(consultation)}
                                    role="radio"
                                    aria-label={`${consultation.title} for ${consultation.duration} ${consultation.duration == 1 ? 'hour' : 'hours'} for $${consultation.price}`}
                                    aria-checked={selectedConsultation == consultation}
                                    className={`px-2 w-full md:w-1/2`} >
                                        <div
                                            className={`flex w-full space-x-4 bg-light-gray rounded-lg px-4 py-8 mb-4 outline outline-primary items-center ${selectedConsultation == consultation ? 'outline-2' : 'outline-0'}`}>
                                            
                                            <div className="text-left grow leading-tight">
                                                <Text content={consultation.title} weight="bold" size="lg" />
                                                <Text content={`${consultation.duration} ${consultation.duration == 1 ? 'hour' : 'hours'}`} size="base" />
                                            </div>
                                            <span className="py-1 px-2 bg-primary rounded-md text-white"><Text content={`$${consultation.price}`} size="sm" /></span>
                                        </div>
                                </button>)}
                            </div>
                        </div>
                        <Form form={props.form} data={formData} errors={formErrors} onChange={onFormChange}/>
                        <div className="flex justify-end w-full">
                            <Button label="Next" disabled={!validated} onClick={next} />
                        </div>
                    </TabPanel>
                    <TabPanel id="panelScheduling" className="space-y-4" selected={step == 2}>
                        {enableScheduling && !scheduled && <Cal
                            calOrigin={process.env.NODE_ENV === 'development' ? 'https://cal.dev' : 'https://cal.com'}
                            calLink={process.env.NODE_ENV === 'development' ? '328studios/test' : selectedConsultation.calendar}
                            config={{
                                name: `${formData.first_name} ${formData.last_name}`,
                                email: formData.email,
                            }}/>}
                        {enableScheduling && bookingData && <Text size="2xl">
                            <strong>{bookingData.booking.title}</strong> has been scheduled for <strong>{ moment(bookingData.date).format('LLLL') }</strong>
                        </Text>}
                        <div className="flex justify-end w-full">
                            <Button label={processing ? "Submitting" : "Submit"} disabled={(enableScheduling && !scheduled) || disableSubmit} onClick={submit} />
                        </div>
                    </TabPanel>
                    <TabPanel id="panelConfirmation" className="space-y-4" selected={step == 3}>
                        <Text size="2xl" content="Thanks! We have recieved your information." />
                    </TabPanel>
                </div>
            </Container>
        </Section>
    )
}

