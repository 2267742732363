'use client';

import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { SlowMo } from "gsap/EasePack";
import { useRef } from "react";

import Container from "../../../components/core/container";
import Section from "../../../components/core/section";
import { Heading } from "@/components/core/heading";
import Text from "@/components/core/text";

import { FormData } from "@/modules/form";
import { Button } from "@/components/core/button";

import { Navigation } from "@/models/navigation";
import Link from "next/link";
import { getSocialMediaIconClass } from "@/shared/util";
import ContactFormWrapper from "./wrapper";


gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(SlowMo);

interface Data {
    contact: {
        email: string,
        phone_number: string,
        social_media: Navigation
    },
    
    scheduling: {
        quick_call: string
    },

    pages: {
        start: {
            id: string,
            url: string
        },
        contact: {
            id: string,
            url: string
        }
    }
}

interface Props {
    title: string,
    content: string,
    form: FormData,
    data: Data
}

export default function ContactForm({ data, ...props }: Props) {

    const container = useRef<HTMLDivElement>(null);
    const tl = useRef<gsap.core.Timeline>();

    const quickCallLink = `${process.env.NODE_ENV === 'development' ? 'https://cal.dev' : 'https://cal.com'}/${process.env.NODE_ENV === 'development' ? '328studios/test' : data.scheduling.quick_call}`

    useGSAP(() => {
        tl.current = gsap.timeline({
            scrollTrigger: {
                trigger: container.current,
                start: "top 75%"
            }
        });

        gsap.set(container.current, { autoAlpha: 0, y: 200 });

        tl.current
            .to(container.current, { autoAlpha: 1, y: 0 })
    }, { scope: container, dependencies: [] });

    return (
        <Section ref={container} className={`space-y-16 py-16 bg-light-gray`}>
            <Container className="block lg:flex gap-8 space-y-16 lg:space-y-0">
                <div className="space-y-4 w-full lg:w-2/6 text-2xl">
                    <Heading content={props.title} leading="tight" />
                    <Text size="2xl" content={props.content} />
                    <div>
                        <div><a href={`tel:${data.contact.phone_number}`}>{data.contact.phone_number}</a></div>
                        <div><a href={`mailto:${data.contact.email}`}>{data.contact.email}</a></div>
                    </div>
                    <div>
                        <ul className="flex items-center space-x-2">
                            {data.contact.social_media?.links.map((link, index) =>
                                <li key={link.page.id}>
                                    <Link href={link.page.url} className="text-3xl" target="_blank">
                                        <i className={`${index == 0 ? 'fa-brands' : 'fa-brands'} fa-${getSocialMediaIconClass(link.page.title)}`}></i>
                                    </Link>
                                </li>
                            )}
                        </ul>
                    </div>
                    <div className="h-8"></div>
                    <div>
                        <Heading content="Looking for something more?" size="3xl" leading="tight" />
                        <div className="space-y-2 mt-4">
                            <Button block={true} className="w-full block" label="Schedule a Quick Call" href={quickCallLink} target="_blank" />
                            <Button block={true} className="w-full block" label="Start a Project" href={data.pages.start.url} />
                        </div>
                    </div>
                </div>
                <ContactFormWrapper form={props.form} className="lg:w-4/6"/>
            </Container>
        </Section>
    )
}

