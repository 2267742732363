'use client';

import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useRef, useState } from 'react';
import useLayoutEffect from "@/hooks/useIsomorphicLayoutEffect";
import { Heading } from "../core/heading";
import CoreText from "../core/text";
import Section from "../core/section";
import Container from "../core/container";
import CoreImage from 'next/image'
import { useThemeColors } from "@/lib/colors";
import { AssetImage } from "@/models/asset";
import { getAssetUrl } from "@/lib/cms";
import './style.css';
import { useCursorEffects } from "@/modules/cursor/useCursorEffects";

gsap.registerPlugin(ScrollTrigger);

interface Props {
  title: string | null,
  content: string | null,
  image: AssetImage | null,
  image_position: "left" | "right" | null,
  image_spill: boolean,
  background: string | null,
  background_color: string | null,
  background_image: string | null,

}

export default function WithImageContent({ image_position = "right", image_spill: spill =  false,...props}: Props) {
  
  const container = useRef<HTMLDivElement>(null);
  const image = useRef<HTMLImageElement>(null);
  const content = useRef<HTMLDivElement>(null);

  const tl = useRef<gsap.core.Timeline>();

  const colors = useThemeColors(props.background_color);

  useCursorEffects(container, {color: colors.cursor});
  
  useGSAP(() => {
      tl.current = gsap.timeline({
        scrollTrigger: {
          trigger: container.current,
          start: "top 50%",
        }
      });
      
      gsap.set(container.current, { autoAlpha: 0 })

      content.current?.children && gsap.set(content.current?.children, { autoAlpha: 0, y: 200 });

      tl.current
        .to(container.current, { autoAlpha: 1, duration: .2 });

      if(image.current) {
        const imageStartX = image_position == "left" ?  100 : -100;
        gsap.set(image.current, { autoAlpha: 0, x: imageStartX })

        tl.current
          .to(image.current, { autoAlpha: 1, x: 0, duration: .5 }, "<");
      }

      content.current?.children && tl.current
        .to(content.current?.children, { autoAlpha: 1, y: 0, stagger: .2 });
  }, { scope: container, dependencies: [] });

  const Text = () => {
    return (
      <div ref={content} className="flex flex-col justify-center w-full md:w-1/2 space-y-4 sm:p-8 mt-8 sm:mt-0">
        {props.title && props.title.length &&
          <Heading as="h2" color={colors.text} size="6xl" content={props.title} />
        }
        <CoreText color={colors.text} content={props.content} />
      </div>
    )
  }

  const Image = () => {
    return (
      <div className={`image-container flex items-center w-full sm:w-1/2 order-first sm:order-none`}>
        <div className={`relative z-50 ${spill ? 'md:translate-y-[13%] pt-[100%] w-full' : undefined}`}>
          {props.image &&
            <CoreImage
              ref={image}
              priority={true}
              className={`rounded-xl max-w-full max-h-full w-full h-full ${spill ? 'absolute top-0 left-0' : undefined}`}
              src={getAssetUrl(props.image)}
              width={props.image.width}
              height={props.image.height}
              alt={props.image.alt ?? ""}
              style={{ 
                objectFit: "cover",
                objectPosition: props.image.focus_css,
              }}
            />
          }
        </div>
      </div>
    )
  }

  return (
    <Section ref={container} background_color={colors.background} className={spill ? 'text-image spill py-24 xl:py-0' : 'py-24'}>
      <Container className={`flex flex-col sm:flex-row space-y-8 sm:space-y-0 items-center justify-center gap-8`}>
        {
          image_position == "right" && 
          <>
            {Text()}
            {Image()}
          </>
        } 
        {
          image_position == "left" &&
          <>
            {Image()}
            {Text()}
          </>
        } 
      </Container>
    </Section>
  )
}