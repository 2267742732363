'use client';

import React, { ReactNode, useEffect, useRef } from "react";

export interface Props {
    className?: string,
    children?: ReactNode,
}

export default function Tabs(props: Props) {

    const tabList = useRef<HTMLDivElement>(null);
    
    useEffect(() => {
        if (!tabList.current) return;

        const tabs = Array.from(tabList.current.children) as HTMLButtonElement[];

        const onKeydown = (e: KeyboardEvent) => {
            const index = tabs.indexOf(e.currentTarget as HTMLButtonElement);
            const length = tabs.length;

            if ((e.key === "ArrowRight") || (e.key === "ArrowDown")) {
                e.preventDefault();

                if (index === length - 1) {
                    focus(tabs[0]);
                } else {
                    focus(tabs[index + 1]);
                }
            } else if ((e.key === "ArrowLeft") || (e.key === "ArrowUp")) {
                e.preventDefault();

                if (index === 0) {
                    focus(tabs[length - 1]);
                } else {
                    focus(tabs[index - 1]);
                }
            } else if (e.key === "Enter") {
                e.preventDefault();

                select(tabs[index]);
            }
        };

        tabs.forEach(tab => {
            tab.addEventListener("keydown", onKeydown);
        });

        return () => {
            tabs.forEach(tab => {
                tab.removeEventListener("keydown", onKeydown);
            });
        }
        
    }, []);

    const focus = (tab: HTMLButtonElement) => {
        tab.focus();
    }
   
    const select = (tab: HTMLButtonElement) => {
        tab.click();
    }
    
    return (
        <div ref={tabList} role="tablist" tabIndex={0} className={`flex flex-col md:flex-row md:space-x-4 ${props.className}`}>
            {props.children}
        </div>
    )
    
};