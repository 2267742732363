'use client';

import './menu.css';

import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import { MouseEvent as ReactMouseEvent, useEffect, useRef, useState } from 'react';
import Link from 'next/link';
import { NavigationState, useNavigation } from '@/modules/navigation/provider';
import { useCursor } from "../../cursor/provider";
import Container from "@/components/core/container";
import Section from "@/components/core/section";

import { Navigation } from '@/models/navigation';
import { useInfiniteMenu } from '@/modules/navigation/menu/useInfiniteMenu';
import MenuBackground from './background';
import { MenuItem } from './item';
import { useCursorEffects } from '@/modules/cursor/useCursorEffects';

interface Props {
  dataMain: Navigation,
  dataSocial: Navigation,
}

export default function Menu({ dataMain, dataSocial }: Props) {

  const { minimizeCursor } = useCursor();

  const menu = useRef<HTMLDivElement>(null);
  const navigationWrapper = useRef<HTMLElement>(null);
  const navigation = useRef<HTMLOListElement>(null);
  const social = useRef<HTMLElement>(null);

  const gsapContext = useRef<gsap.Context>();

  const tl = useRef<gsap.core.Timeline>();
  const tl2 = useRef<gsap.core.Timeline>();

  const [openBackground, setOpenBackground] = useState<boolean | null>(null);
  const [linkText, setLinkText] = useState<string | null>(null);
  
  const { visible, setState, toggle, transitioning } = useNavigation();

  const { clones, order: itemOrder } = useInfiniteMenu(navigation, dataMain.links);

  useCursorEffects(menu, { color: "gray" }, {})
  useCursorEffects(navigationWrapper, { text: "Drag", color: "gray" }, {})

  useGSAP((context) => {
    gsapContext.current = context;

    var duration = 0.4;
    var stagger = 0.1;

    context.add("enter", () => {
      gsap.set(menu.current, { visibility: "visible" });
      gsap.set(navigation.current!.children, { autoAlpha: 0, left: -500 });
      gsap.set(social.current, { autoAlpha: 0, bottom: "-3rem" });
      gsap.set(".fader", { autoAlpha: 0, bottom: "-20rem" });

      tl.current = gsap.timeline({
        onStart: () => {
          document.querySelector("html")!.style.overflow = "hidden";
          document.body.style.overflow = "hidden";
          document.body.style.position = "relative";
          
          setOpenBackground(true)
        },
      });

      tl.current
        .set(".fader", { autoAlpha: 1, bottom: "0rem", delay: 0.8, })
        .to(itemOrder, { autoAlpha: 1, left: 0, duration: duration, stagger: stagger, ease: "power3.out" }, "<-.1")
        .to(social.current, { autoAlpha: 1, bottom: "2rem", duration: duration, ease: "power3.out" }, "<.08")
    });

    context.add("exit", () => {
      if (!navigation.current || !social.current) return;

      setLinkText(null);

      tl2.current = gsap.timeline({
        onStart: () => {
          setLinkText(null);
          setOpenBackground(false);
          document.querySelector("html")!.style.overflow = "auto";
          document.body.style.overflow = "auto";
          document.body.style.position = "static";
        },
      });

      tl2.current
        .to(social.current, { autoAlpha: 0, bottom: "-3rem", duration: duration, ease: "power3.out" })
        .to(itemOrder, { autoAlpha: 0, left: -500, duration: duration, stagger: { from: "start", each: stagger }, ease: "power3.out" }, "<")
        .set(".fader", { autoAlpha: 0, bottom: "-20rem" }, "<-1");
    });

    

    context.add("hide", () => {
      if (!menu.current) return;

      gsap
        .set(menu.current, { visibility: "hidden", delay: .5 })
        .eventCallback("onComplete", () => {
          setState(NavigationState.CLOSED);
        });
    });
  }, { scope: menu, dependencies: [itemOrder] });

  useEffect(() => {
    if (!gsapContext.current) return;

    if (visible == NavigationState.OPENING) {
      gsapContext.current.enter();
    } else if (visible == NavigationState.CLOSING) {
      gsapContext.current.exit();
    }
  }, [visible]);
  
  const onMouseEnterLink = (e: ReactMouseEvent<HTMLAnchorElement> | Event) => {
    if (transitioning) return;

    minimizeCursor("gray");

    if (e.currentTarget && (e.currentTarget as HTMLAnchorElement).dataset.title) {
      const title = (e.currentTarget as HTMLAnchorElement).dataset.title;
      
      if(title) {
        setLinkText(title);
      }
    }
  }
  
  const onMouseLeaveLink = () => {
    setLinkText(null);
  }

  const onEnterComplete = () => {
    setState(NavigationState.OPENED);
  }

  const onExitComplete = () => {
    gsapContext.current && gsapContext.current.hide();
  }

  const getSocialMediaIconClass = function (platform: string) {
    switch (platform.toLowerCase()) {
      case "facebook":
        return "facebook";
      case "instagram":
        return "instagram";
      case "linkedin":
        return "linkedin";
    }
  }

  return (
    <Section id="menu" ref={menu} className="fixed top-0 left-0 w-screen h-[100dvh] z-30 overflow-hidden invisible" attributes={{
      role: "menu",
      "aria-labelledby": "menu-toggle",
      "aria-hidden": visible == NavigationState.OPENED
    }}>
      <Container className="relative z-10 h-full mx-auto">
        <nav ref={navigationWrapper} className='main-navigation h-full overflow-hidden'>
          <ol ref={navigation}>
            {dataMain.links.map((link, index) =>
              <MenuItem key={link.page.id} index={index} link={link} onMouseEnter={onMouseEnterLink} onMouseLeave={onMouseLeaveLink} onClick={() => toggle()}/>
            )}
            {clones.map((link, index) =>
              <MenuItem key={link.page.id} clone={true} index={index} link={link} onMouseEnter={onMouseEnterLink} onMouseLeave={onMouseLeaveLink} onClick={() => toggle()} />
            )}
          </ol>
          <div className="fader"></div>
        </nav>
        <nav ref={social} className="social-navigation absolute right-4 bottom-8">
          <ol className="flex items-center space-x-4">
            <li><span className="font-display text-4xl uppercase font-bold">Social</span></li>
            {dataSocial.links.map((link, index) =>
              <li key={link.page.id}>
                <Link href={link.page.url} className="text-4xl text-secondary" data-title={link.page.title} onMouseEnter={onMouseEnterLink} onMouseLeave={onMouseLeaveLink} onClick={() => toggle()} aria-label={link.page.title}>
                  <i className={`${index == 0 ? 'fa-brands' : 'fa-brands'} fa-${getSocialMediaIconClass(link.page.title)}`} aria-hidden={true}></i>
                </Link>
              </li>
            )}
          </ol>
        </nav>
      </Container>
      <MenuBackground open={openBackground} word={linkText} onEnterComplete={onEnterComplete} onExitComplete={onExitComplete} />    
    </Section>
  )
}


