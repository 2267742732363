const config = {
    ga: {
        website: "G-9773NFX8MY",
        links: "G-EHR5V58DXJ",
    },
    url: {
        development: "http://localhost:8000",
        production: "https://cms.328studios.co",
    },
    media: {
        development: "https://cms.328studios.co",
        production: "https://cms.328studios.co",
    },
    api: {
        development: "http://localhost:8000/api",
        production: "https://cms.328studios.co/api",
    },
    graphql: {
        development: "https://cms.328studios.co/graphql",
        production: "https://cms.328studios.co/graphql",
    },
};

export default config;