import { MutableRefObject, RefObject, useEffect, useState } from "react";
import { gsap } from "gsap";

export function useMagnetize(button: MutableRefObject<HTMLAnchorElement | HTMLButtonElement | undefined>, scale: number = 1.1) {
    

    const magneticPullX: number = 0.4;
    const magneticPullY: number = 0.5;

    const [hovering, setHovering] = useState(false);
    
    useEffect(() => {
        if (!button.current) return;

        const currentButton = button.current as HTMLElement;

        let x = 0,  y = 0;

        const onMouseEnter = () => {
            if (!button.current) return;

            gsap.to(button.current, { scale: scale });
        }

        const onMouseLeave = () => {
            if (!button.current) return;

            gsap.to(button.current, { scale: 1, x: 0, y: 0 });
        }

        const onMagnetise = () => {
            if (!button.current) return;

            gsap.to(button.current, {
                x: x,
                y: y,
                duration: 0.4,
                ease: "power4",
                onComplete: () => {
                }
            });
        }

        const onDemagnetise = () => {
            if (!button.current) return;

            gsap.to(button.current, {
                x: 0,
                y: 0,
                duration: 0.3,
                ease: "elastic.out.config(1.1, 0.5)"
            });
        }

        const getDistance = (x1: number, y1: number, x2: number, y2: number) => {
            var a = x1 - x2;
            var b = y1 - y2;

            return Math.hypot(a, b);
        }

        const onMouseMove = ({ clientX, clientY }: MouseEvent) => {
            const rect = button.current!.getBoundingClientRect();

            const distance = getDistance(clientX, clientY, rect.left + rect.width / 2, rect.top + rect.height / 2);

            const hoverArea = (hovering ? 0.4 : 0.3) * rect.width;
            
            if (distance < hoverArea) {
                if (!hovering) {
                    setHovering(true);
                }

                x = (clientX - (rect.left + rect.width / 2)) * magneticPullX;
                y = (clientY - (rect.top + rect.height / 2)) * magneticPullY;

                onMagnetise();
            } else {
                if (hovering) {
                    onDemagnetise();
                    setHovering(false);
                }
            }
        }


        if (currentButton) {
            currentButton.addEventListener("mouseenter", onMouseEnter);
            currentButton.addEventListener("mouseleave", onMouseLeave);
            currentButton.addEventListener("mousemove", onMouseMove);
        }

        return () => {
            currentButton.removeEventListener("mouseenter", onMouseEnter);
            currentButton.removeEventListener("mouseleave", onMouseLeave);
            currentButton.removeEventListener("mousemove", onMouseMove);
        };
    }, [button, scale, hovering]);
}