'use client';
// @ts-ignore
import Tempus from "@studio-freight/tempus";
import Lenis from "@studio-freight/lenis";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect, useLayoutEffect, useRef } from "react";
import { usePathname, useSearchParams } from "next/navigation";
import ReactLenis from '@studio-freight/react-lenis';
import { useLenis } from "@studio-freight/react-lenis";

export function SmoothScroller() {
    const lenis = useLenis();

    const pathname = usePathname();
    const searchParams = useSearchParams();
    
    useEffect(() => {
        window.scrollTo(0, 0);
        
        if (!lenis) return;
        lenis.scrollTo(0, { immediate: true });
    }, [pathname, searchParams, lenis]);

    useLayoutEffect(() => {
        if (!lenis) return;

        const resize = setInterval(() => {
            lenis!.resize();
        }, 150);

        function onFrame(time: number) {
            lenis!.raf(time);
        }

        const unsubscribe = Tempus.add(onFrame);

        lenis.on('scroll', ScrollTrigger.update)

        return () => {
            unsubscribe();
            clearInterval(resize);
        };
    }, [lenis]);
    
    return (<></>);
}


export function LenisProvider({
    children,
    options,
    ...props
}: {
    children: React.ReactNode;
    options?: any;
}) {
    return (
        <ReactLenis root {...props}>
            {children}
        </ReactLenis>
    );
}