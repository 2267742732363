'use client';

import Image from "next/image";
import { Heading } from "../../../components/core/heading";
import Text from "../../../components/core/text";

import { Initiative } from "@/models/initiatives";
import { ThemeColorSet } from "@/lib/colors";

interface Props {
    number: number,
    initiative: Initiative,
    colors: ThemeColorSet
}

export default function InitiativeTile({ colors, ...props}: Props) {

    return(
        <div className="flex w-full py-16 justify-between items-center">
            <div className="grow space-y-2 max-w-xl">
                <Heading as="h3" size="6xl" weight="bold" color={colors.text}>{props.initiative.title}</Heading>
                <Text content={props.initiative.content}/>
            </div>
            <div className="flex space-x-4 items-center order-first md:order-none pr-8 md:pr-0">
                <Heading size="8xl" weight="bold" color={colors.text}>0{props.number}</Heading>
            </div>
        </div>
    )
}