'use client';

import { useState } from "react";

import Text from "@/components/core/text";

import Form, { FormData, handleErrorResponse } from "@/modules/form";
import { Button } from "@/components/core/button";

import { getClient } from "@/lib/rest";
import { getFormUrl } from "@/lib/cms";

import { Tab, TabPanel, Tabs } from "@/components/core/tabs";

interface Props {
    compact?: boolean,
    form: FormData,
    className?: string,
}

export default function ContactFormWrapper(props: Props) {

    const {
        compact = false,
    } = props;

    const [formData, setFormData] = useState<{ [key: string]: any }>({});
    const [formErrors, setFormErrors] = useState<{ [key: string]: any }>({});

    const [step, setStep] = useState(1);
    const [disableSubmit, setDisableSubmit] = useState(false);

    const [validated, setValidated] = useState(false);
    const [completed, setCompleted] = useState(false);
    const [processing, setProcessing] = useState(false);

    const submit = () => {
        setProcessing(true);
        setDisableSubmit(true);
        setFormErrors({});

        getClient()
            .post(`${getFormUrl(props.form)}`, formData)
            .then((response) => {
                setDisableSubmit(false);
                setCompleted(true);
                setStep(2);
                setProcessing(false);
            })
            .catch(async (errorOrResponse: Error | Response) => {
                const errors = await handleErrorResponse(errorOrResponse);

                setDisableSubmit(false);
                setFormErrors(errors);
                setProcessing(false);
            })
    }

    const onFormChange = (values: { [key: string]: any}, validated: boolean) => {
        setFormData(values);
        setValidated(validated);
    }

    return (
        <div className={`form-wrapper w-full ${compact ? 'form-wrapper-compact' : ''} ${props.className ?? ''}`}>
            <Tabs className="form-tabs" >
                <Tab id="panelMessage" selected={step == 1} disabled={completed} onClick={(e) => setStep(1)}>01 Message</Tab>
                <Tab id="panelConfirmation" selected={step == 2} disabled={!completed}>02 Confirmation</Tab>
            </Tabs>
            <TabPanel id="panelMessage" className="space-y-4" selected={step == 1}>
                <Form form={props.form} data={formData} errors={formErrors} onChange={onFormChange} />
                <div className="flex justify-end w-full">
                    <Button label={processing ? "Sending" : "Send"} disabled={!validated || disableSubmit} onClick={submit} />
                </div>
            </TabPanel>
            <TabPanel id="panelConfirmation" className="space-y-4" selected={step == 2}>
                <Text size="2xl" content="Thanks! We have recieved your message and will be in touch soon." />
            </TabPanel>
        </div>
    )
}

