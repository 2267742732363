'use client';

import { useCursor } from "@/modules/cursor/provider";
import Image from "next/image";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { CSSProperties, ForwardedRef, forwardRef, ReactNode, useRef, useState } from 'react';
import { AssetImage } from "@/models/asset";
import { getAssetUrl } from "@/lib/cms";
import useNextBlurhash from "use-next-blurhash";

gsap.registerPlugin(ScrollTrigger);

const bg_opacities = {
    0: "bg-opacity-0",
    5: "bg-opacity-5",
    10: "bg-opacity-10",
    20: "bg-opacity-20",
    25: "bg-opacity-25",
    30: "bg-opacity-30",
    40: "bg-opacity-40",
    50: "bg-opacity-50",
    60: "bg-opacity-60",
    70: "bg-opacity-70",
    75: "bg-opacity-75",
    80: "bg-opacity-80",
    90: "bg-opacity-90",
    95: "bg-opacity-95",
}

const opacities = {
    0: "opacity-0",
    5: "opacity-5",
    10: "opacity-10",
    20: "opacity-20",
    25: "opacity-25",
    30: "opacity-30",
    40: "opacity-40",
    50: "opacity-50",
    60: "opacity-60",
    70: "opacity-70",
    75: "opacity-75",
    80: "opacity-80",
    90: "opacity-90",
    95: "opacity-95",
}

interface Props {
    id?: string,
    background_color?: string,
    background_opacity?: keyof typeof bg_opacities,
    overlay_color?: string,
    overlay_gradient?: string,
    overlay_opacity?: keyof typeof opacities,
    background_image?: AssetImage | null,
    background_image_opacity?: number,
    background_mix?: "normal" | "multiply" | "screen" | "overlay" | "darken" | "lighten" | "color-dodge" | "color-burn" | "hard-light" | "soft-light" | "difference" | "exclusion" | "hue" | "saturation" | "color" | "luminosity",
    background_position?: string,
    className?: string,
    classes?: string,
    style?: CSSProperties,
    children?: ReactNode,
    attributes?: { [key: string]: string | number | boolean }
}
const Section = forwardRef(function Section(props: Props, ref: ForwardedRef<HTMLDivElement>) {
    const {
        id,
        className = "",
        classes = "",
        style = {},
        children,
    } = props;

    const [blurDataUrl] = useNextBlurhash(props.background_image?.blurhash ?? "LEHLh[WB2yk8pyoJadR*.7kCMdnj");

    var combinedClasses: string[] = [];

    var combinedOverlayClasses: string[] = [];

    if (className) {
        combinedClasses.push(className);
    }

    if (classes) {
        combinedClasses.push(classes);
    }

    if (props.background_color) {
        combinedClasses.push(`bg-${props.background_color}`);

        if(props.background_color.indexOf("#") == 0) {
            style["backgroundColor"] = props.background_color;
        }
    }

    if (props.background_image) {
        combinedClasses.push("has-background-image");
        combinedClasses.push("relative");
    }

    if(props.background_opacity) {
        combinedClasses.push(bg_opacities[props.background_opacity]);
    }

    if (props.overlay_color) {
        combinedClasses.push("relative");
        //combinedOverlayClasses.push(`bg-${props.overlay_color}`);

        if (props.overlay_color.indexOf("#") == 0) {
            //style["backgroundColor"] = props.overlay_color;
        }
    }

    if (props.overlay_opacity) {
        combinedOverlayClasses.push(opacities[props.overlay_opacity]);
    }

    return (
        <section ref={ref} id={id} className={combinedClasses.join(" ")} style={style} {...props.attributes}>
            { props.background_image &&
                <>
                    <div className="relative z-[3]">
                        {children}
                    </div>
                    {props.overlay_gradient && <div className={`section-background-overlay absolute top-0 left-0 w-full h-full z-[2] ${props.overlay_gradient}`}></div>}
                    <div className={`section-background-overlay absolute top-0 left-0 w-full h-full z-[1] ${combinedOverlayClasses.join(" ")} `}>
                        <div className="section-background-image absolute top-0 left-0 w-full h-full">
                            <Image 
                                priority={true} 
                                src={getAssetUrl(props.background_image)} 
                                width={props.background_image?.width} height={props.background_image?.height} 
                                alt={props.background_image.alt ?? ""}
                                placeholder={`${props.background_image.blurhash ? 'blur' : 'empty'}`}
                                blurDataURL={props.background_image.blurhash ? blurDataUrl : undefined}
                                style={{
                            opacity: props.background_image_opacity ? props.background_image_opacity / 100 : 1,
                                width: '100%',
                                height: '100%',
                                maxWidth: 'none',
                                objectFit: "cover",
                                objectPosition: props.background_image ? props.background_image?.focus_css : "50% 50%",
                                mixBlendMode: props.background_color ? props.background_mix : "normal"
                            }} />
                        </div>
                    </div>
                </>
            }
            { !props.background_image &&
                <>
                {children}
                </>
            }
        </section>
    )
});


export default Section;