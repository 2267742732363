'use client';

import dynamic from 'next/dynamic';

import Text from "@/components/core/text";

import { useEffect, useState } from "react";
import { Field, FormData } from "@/modules/form";
import { FormError } from '../errors';

const Select = dynamic(() => import("react-select"), { ssr: false });


interface Props {
    form: FormData,
    field: Field,
    value?: any,
    errors?: string[],
    onChange: ((value: any) => void),
    onBlur: ((value: any) => void)
}

interface SelectOption { 
    value: string,
    label: string
}

export function FormField({ form, field, value, errors, onChange, onBlur }: Props) {

    const widths = {
        25: "w-1/2",
        33: "w-1/3",
        50: "w-1/2",
        66: "w-2/3",
        75: "w-3/4",
        100: "w-full",
    }

    return (
        <>
            { field.visibility != "hidden" &&
                <div className={`form-field space-y-2 w-full md:${widths[field.width]}`}>
                    <div className="form-field-label space-x-2">
                        <Text as="label" family="display" size="xl" weight="bold" content={field.display} attributes={{
                            "htmlFor": `form-${form.handle}-${field.handle}`
                        }}/>
                        {field.validate && field.validate.includes("required") && <span className='bg-red-400 text-white text-xs p-1 rounded-sm leading-none'>Required</span>}
                    </div>
                    {field.type == "text" && <TextInput form={form} field={field} value={value} errors={errors} onChange={onChange} onBlur={onBlur} />}
                    {field.type == "textarea" && <TextareaInput form={form} field={field} value={value} errors={errors} onChange={onChange} onBlur={onBlur} />}
                    {field.type == "select" && <SelectInput form={form} field={field} value={value} errors={errors} onChange={onChange} onBlur={onBlur} />}
                    {errors && errors[0] && <FormError form={form} handle={field.handle} error={errors[0]}/>}
                </div>
            }
            {field.visibility == "hidden" && <HiddenInput form={form} field={field} value={value} onChange={onChange} onBlur={onBlur} />}
        </>
    )
}

function HiddenInput({ field, value, errors, onChange, onBlur }: Props) {
    return (
        <input
            type="hidden"
            name={field.handle}
            defaultValue={value ?? field.default}/>
    )
}


function TextInput({ form, field, value, errors, onChange, onBlur }: Props) {
    return (
        <input
            
            id={`form-${form.handle}-${field.handle}`}
            className="form-input"
            type={field.input_type}
            name={field.handle}
            autoComplete={field.autocomplete ?? 'off'}
            placeholder={field.placeholder}
            defaultValue={value ?? field.default}
            aria-required={field.validate && field.validate.includes("required")}
            aria-invalid={errors && errors.length ? true : false}
            aria-describedby={`form-${form.handle}-${field.handle}-error`}
            onChange={(e) => onChange(e.target.value)}
            onBlur={(e) => onBlur(e.target.value)} />
    )
}

function TextareaInput({ form, field, value, errors, onChange, onBlur }: Props) {
    return (
        <textarea
            id={`form-${form.handle}-${field.handle}`}
            className="form-input"
            name={field.handle}
            placeholder={field.placeholder}
            rows={4}
            defaultValue={value ?? field.default}
            aria-required={field.validate && field.validate.includes("required")}
            aria-invalid={errors && errors.length ? true : false}
            aria-describedby={`form-${form.handle}-${field.handle}-error`}
            onChange={(e) => onChange(e.target.value)}
            onBlur={(e) => onBlur(e.target.value)} />
    )
}

function SelectInput({ form, field, value, errors, onChange, onBlur }: Props) {

    const [options, setOptions] = useState<SelectOption[]>([]);
    const [defaultOption, setDefaultOption] = useState < SelectOption | SelectOption[] | null>(null);
    const [selectedOptions, setSelectedOptions] = useState<any>();

    useEffect(() => {
        const normalizeOptions: SelectOption[] = [];

        if (typeof field.options !== 'undefined') {
            Object.keys(field.options).map(handle => {
                normalizeOptions.push({
                    value: handle,
                    label: field.options![handle]
                })
            });

            setOptions(normalizeOptions);

            let defaultOptions;
            
            if(field.multiple) {
                
                const fieldDefaults = (field.default ?? "").split(/\s*,\s*/);

                defaultOptions = normalizeOptions.filter(option => {

                    if (typeof value !== 'undefined') {
                        return value.includes(option.value);
                    }

                    return fieldDefaults.includes(option.value);
                });
            } else {
                defaultOptions = normalizeOptions.find(option => {
                    return option.value == (value ?? field.default);
                }) ?? null;
            }

            setDefaultOption(defaultOptions);
        }
    }, [field, value]);

    const onSelect = (selectedOptions: any) => {

        setSelectedOptions(selectedOptions);

        if(field.multiple) {
            const normalizeValue = (selectedOptions as SelectOption[]).map(option => option.value);
            onChange(normalizeValue);
            onBlur(normalizeValue);
        } else {
            onChange((selectedOptions as SelectOption).value);
            onBlur((selectedOptions as SelectOption).value);
        }

    }

    const onSelectBlur = () => {
        if (typeof selectedOptions === 'undefined') {
            onBlur(selectedOptions);
            return;
        }

        if (field.multiple) {
            const normalizeValue = (selectedOptions as SelectOption[]).map(option => option.value);
            onBlur(normalizeValue);
        } else {
            onBlur((selectedOptions as SelectOption).value);
        }
    }

    return (
        <Select
            id={`form-${form.handle}-${field.handle}`}
            className="form-input form-input-select"
            classNamePrefix="select"
            name={field.handle}
            options={options}
            value={defaultOption}
            placeholder={field.placeholder ?? "Select Option"}
            aria-required={field.validate && field.validate.includes("required")}
            aria-invalid={errors && errors.length ? true : false}
            aria-describedby={`form-${form.handle}-${field.handle}-error`}
            isClearable={field.clearable}
            isMulti={field.multiple}
            isSearchable={field.searchable}
            theme={(theme) => ({
                ...theme,
                colors: {
                    ...theme.colors,
                    
                    primary: '#00A6ED',
                    primary75: '#1aafef',
                    primary50: '#33b8f1',
                    primary25: '#4dc1f2',
                },
            })}
            onChange={onSelect}
            onBlur={onSelectBlur}/>
    )
}
