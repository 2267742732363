'use client';

import { CSSProperties, LegacyRef, ReactNode, forwardRef } from 'react';
import React from "react";
import { TextProps } from '.';
import { HeadingProps } from '../heading/basic';

export interface BaseTextProps {
    align?: "left" | "center" | "right" | "justify" | "start" | "end",
    family?: "display" | "body",
    size?: "default" | "xs" | "sm" | "base" | "lg" | "xl" | "2xl" | "3xl" | "4xl" | "5xl" | "6xl" | "7xl" | "8xl" | "9xl" | "10xl" | "11xl" | "12xl" | "13xl" | "14xl" | "15xl" | "16xl" | "17xl" | "18xl" | "19xl" | "20xl" |"title",
    weight?: "thin" | "extralight" | "light" | "normal" | "medium" | "semibold" | "bold" | "extrabold" | "black" | "display",
    leading?: "none" | "tight" | "snug" | "normal" | "relaxed" | "loose"
    transform?: "uppercase" | "lowercase" | "capitalize" | "normal-case",
    whiteSpace?: "normal" | "nowrap" | "pre" | "pre-line" | "break-spaces" | null
    color?: string,
    children?: ReactNode,
    content?: any,
    className?: string;
    classes?: string;
    style?: CSSProperties,
    attributes?: { [key:string]: string },
    forwardedRef?: React.Ref<HTMLHeadingElement | HTMLSpanElement>
}

interface Elements {
    h1: React.DetailedHTMLProps<React.HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement>,
    h2: React.DetailedHTMLProps<React.HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement>,
    h3: React.DetailedHTMLProps<React.HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement>,
    h4: React.DetailedHTMLProps<React.HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement>,
    h5: React.DetailedHTMLProps<React.HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement>,
    h6: React.DetailedHTMLProps<React.HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement>,
    p: React.DetailedHTMLProps<React.HTMLAttributes<HTMLParagraphElement>, HTMLParagraphElement>,
    span: React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>,
    label: React.DetailedHTMLProps<React.HTMLAttributes<HTMLLabelElement>, HTMLSpanElement>,
}

const FontSizes = {
    "default": "",
    "xs": "text-sm md:text-xs",
    "sm": "text-sm md:text-sm",
    "md": "text-sm md:text-md",
    "lg": "text-md md:text-lg",
    "xl": "text-lg md:text-xl",
    "2xl": "text-xl md:text-2xl",
    "3xl": "text-2xl md:text-3xl",
    "4xl": "text-3xl md:text-4xl",
    "5xl": "text-3xl md:text-5xl",
    "6xl": "text-4xl md:text-6xl",
    "7xl": "text-5xl md:text-7xl",
    "8xl": "text-6xl md:text-8xl",
    "9xl": "text-7xl md:text-9xl",
    "10xl": "text-8xl md:text-10xl",
    "11xl": "text-9xl md:text-11xl",
    "12xl": "text-10xl md:text-12xl",
    "13xl": "text-11xl md:text-13xl",
    "14xl": "text-12xl md:text-14xl",
    "15xl": "text-13xl md:text-15xl",
    "16xl": "text-14xl md:text-16xl",
    "17xl": "text-15xl md:text-17xl",
    "18xl": "text-16xl md:text-18xl",
    "19xl": "text-17xl md:text-19xl",
    "20xl": "text-18xl md:text-20xl",
    "title": "text-title"
};

export default forwardRef(function BaseText(props: TextProps | HeadingProps, ref: LegacyRef<any>) {

    var combinedClasses: string[] = [];

    if (props.className) {
        combinedClasses.push(props.className);
    }

    if (props.whiteSpace) {
        combinedClasses.push(`whitespace-${props.whiteSpace}`);
    }

    if(props.weight) {
        combinedClasses.push(`font-${props.weight}`);
    } else {
        combinedClasses.push(`font-normal`);
    }

    if (props.family) {
        combinedClasses.push(`font-${props.family}`);
    } else {
        combinedClasses.push(`font-body`);
    }

    if (props.align) {
        combinedClasses.push(`text-${props.align}`);
    }

    if (props.leading) {
        combinedClasses.push(`leading-${props.leading}`);
    }

    if(props.transform) {
        combinedClasses.push(`${props.transform}`);
    }

    if(props.size) {
        combinedClasses.push(FontSizes[props.size as keyof typeof FontSizes]);
    }


    

    var style: CSSProperties = props.style ?? {};

    if (props.color) {
        combinedClasses.push(`text-${props.color}`);

        if (props.color.indexOf("#") == 0) {
            style["color"] = props.color;
        }
    }

    const Tag = props.as as keyof Elements;

    if (props.content) {
        return (
            <Tag ref={ref} className={`${combinedClasses.join(" ")}`} style={style} dangerouslySetInnerHTML={{ __html: props.content }} {...props.attributes}>
            </Tag>
        )
    } else {
        return (
            <Tag ref={ref} className={`${combinedClasses.join(" ")}`} style={style} {...props.attributes}>
                {props.children}
            </Tag>
        )
    }
});