'use client';

import { FormData } from "@/modules/form";

interface Props {
    form: FormData,
    errors?: Errors,
}

interface ErrorProps {
    form: FormData,
    handle: string,
    error: string,
}

interface Errors {
    [key: string]: any,
}

export function FormErrors({ form, errors }: Props) {

    if (typeof errors === 'undefined' || !Object.keys(errors).length) {
        return (<></>);
    }

    return (
        <div id={`${form.handle}-errors`} aria-label={`There were ${errors.length} found in this form`} className="bg-red-400 py-4 px-2 rounded-lg">
            {errors && Object.keys(errors).map(handle => <FormError key={handle} form={form} handle={handle} error={errors[handle]} />)}
        </div>
    )
}

export function FormError({ form, handle, error }: ErrorProps) {

    return (
        <div id={`form-${form.handle}-${handle}-error`} className="bg-red-400 w-full text-white text-md rounded-sm px-2">{error}</div>
    )
}

export async function handleErrorResponse(errorOrResponse: Error | Response): Promise<{ [key: string]: string }> {
    if (errorOrResponse instanceof Error) {
        return {
            form: "Oops! Something went wrong. Please try again."
        }
    } else {
        const data = await errorOrResponse.json();
        switch(errorOrResponse.status) {
            case 400:
                return data.error;
            case 419:
                return {
                    form: "Oops! This page has expired. Please refresh and try again."
                };
            default:
                return {
                    form: "Oops! Something went wrong. Please try again."
                }
        }
    }
}

