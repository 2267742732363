'use client';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect, useRef } from "react";
import Container from "@/components/core/container";
import Section from "@/components/core/section";
import Text from "@/components/core/text";
import { Heading } from "@/components/core/heading";

gsap.registerPlugin(ScrollTrigger);

interface Props {
    name: string,
    title: string,
    content: string
}

export default function CaseStudySection(props: Props) {

    const tl = useRef<gsap.core.Timeline>();
    const container = useRef<HTMLDivElement>(null);

    useEffect(() => {
        tl.current = gsap.timeline();

        gsap.set(container.current!.children, { autoAlpha: 0, y: 200 });

        tl.current
            .to(container.current!.children, { autoAlpha: 1, y: 0, stagger: 0.1 });

    }, []);

    return (
        <Section>
            <Container ref={container}>
                <div className="grid grid-cols-1 md:grid-cols-6 gap-8">
                    <div className="col-span-6 md:col-start-1 md:col-span-2">
                        <Heading as="h2" size="3xl" transform="uppercase" content={props.name} className="mt-4"/>
                    </div>
                    <div className="col-span-6 md:col-start-3 md:col-span-4 space-y-4">
                        <Heading content={props.title} />
                        <div className="max-w-screen-md pr-2 sm:pr-0 prose lg:prose-xl">
                            <Text content={props.content} />
                        </div>
                    </div>
                </div>
            </Container>
        </Section>
    );
}