
export interface ThemeColorSet { 
    background: string,
    text: string, 
    accent: string,
    accent_text: string,
    cursor: string
};

export function useThemeColors(color: string | null = "white", inHexcode: boolean = false): ThemeColorSet  {

    let colors;

    color = color ?? "white";

    switch (color.toLowerCase()) {
        case "primary":
        case "blue":
        case "#00a6ed":
            colors = ["primary", "white", "tertiary", "secondary", "tertiary"];
            break;
        case "secondary":
        case "gray":
        case "#3e3e3d":
            colors = ["secondary", "primary", "tertiary", "secondary", "tertiary"];
            break;
        case "tertiary":
        case "yellow":
        case "#f7ff07":
            colors = ["tertiary", "secondary", "primary", "white", "primary"];
            break;
        case "light-gray":
        case "#fbfbfb":
            colors = ["light-gray", "secondary", "primary", "white", "tertiary"];
            break;
        default:
            colors = ["white", "secondary", "primary", "white", "tertiary"];
            break;
    }

    return {
        background: inHexcode ? colorToHex(colors[0]) : colors[0],
        text: inHexcode ? colorToHex(colors[1]) : colors[1],
        accent: inHexcode ? colorToHex(colors[2]) : colors[2],
        accent_text: inHexcode ? colorToHex(colors[3]) : colors[3],
        cursor: inHexcode ? colorToHex(colors[4]) : colors[4],
    };
}

export const colorToHex = (value?: string | null): string => {

    var translated = value;

    switch (value) {
        case "#00A6ED":
        case "blue":
        case "primary":
            translated = "#00A6ED";
            break;
        case "#F7FF07":
        case "yellow":
        case "tertiary":
            translated = "#F7FF07";
            break;
        case "#3E3E3D":
        case "gray":
        case "secondary":
            translated = "#3E3E3D";
            break;
        case "#fbfbfb":
        case "light-gray":
            translated = "#fbfbfb";
            break;
        case "#ffffff":
        case "white":
            translated = "#ffffff";
            break;
        default:
            translated = "#00A6ED";
            break;
    }

    return translated;
}
