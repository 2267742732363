import Link from "next/link";
import { Announcement as Data } from "@/models/announcement";

interface Props {
    announcement: Data
}

export default function Announcement({ announcement, ...props }: Props) {

    if (!announcement.enabled || (announcement.enabled && !announcement.message.length)) {
        return (<></>);
    }

    return (
         <>
            {
                announcement.link && <Link className="bg-secondary text-white text-center text-lg py-2 w-full block" href={announcement.link} target="_blank">{announcement.message} <i aria-hidden={true} className="fa fa-chevron-right text-base"></i></Link>
            }
            {
                !announcement.link && <div className="bg-secondary text-white text-center text-lg py-2 w-full block">{announcement.message}</div>
            }
        </>


       
    )
}
