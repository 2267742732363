'use client';

import { ReactNode, useEffect, useRef } from "react";

export interface Props {
    id: string,
    selected: boolean,
    className?: string,
    disabled?: boolean,
    children?: ReactNode,
    onClick?: ((e: React.MouseEvent<HTMLButtonElement>) => void),
}

export default function Tab(props: Props) {
    
    const tab = useRef<HTMLButtonElement>(null);

    useEffect(() => {
        if (!tab.current) return;

        if (props.selected) {
            tab.current.focus();
        }
    }, [props.selected]);
    
    return (
        <button ref={tab} id={`${props.id}-tab`} role="tab" aria-selected={props.selected} aria-controls={props.id} tabIndex={props.selected ? 0 : -1} className={props.selected ? 'active' : ''} disabled={props.disabled} onClick={props.onClick}>{props.children}</button>
    )
    
};