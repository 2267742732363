'use client';

import Image from "next/image";
import { Heading } from "../../../components/core/heading";
import Text from "../../../components/core/text";

import { Commitment } from "@/models/accessibility/commitments";
import { ThemeColorSet } from "@/lib/colors";

interface Props {
    number: number,
    commitment: Commitment,
    colors: ThemeColorSet
}

export default function CommitmentCard({ colors, ...props}: Props) {

    return(
        <div className="flex w-full py-8 md:py-16">
            <div className="space-y-2 flex-col md:max-w-lg">
                <Heading size="8xl" weight="bold" color={colors.text}>0{props.number}</Heading>
                <Heading as="h3" size="6xl" weight="bold" color={colors.text}>{props.commitment.title}</Heading>
                <Text content={props.commitment.content} />
            </div>
        </div>
    )
}