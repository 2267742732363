'use client';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useRef } from "react";
import { Button, ButtonCircle } from "@/components/core/button";
import { Heading } from "@/components/core/heading";
import Text from "@/components/core/text";
import Container from "@/components/core/container";
import Image from 'next/image'
import Section from "@/components/core/section";
import { useThemeColors } from "@/lib/colors";
import { AssetImage } from "@/models/asset";
import { getAssetUrl } from "@/lib/cms";

gsap.registerPlugin(ScrollTrigger);

interface Data {
    pages: {
        consulting: {
            id: string,
            url: string
        }
    }
}

interface Props {
    background_image: AssetImage | null,
    data: Data
}

export default function ConsultationCTA({ data, ...props}: Props) {
    const title = useRef<HTMLDivElement>(null);
    const content = useRef<HTMLDivElement>(null);
    const image = useRef<HTMLImageElement>(null);

    const colors = useThemeColors("yellow");

    return (
        <Section background_color="primary" background_image_opacity={20} background_image={props.background_image} background_position={props.background_image ? props.background_image.focus_css : ""} >
            <Container className={`flex items-end md:items-center h-[600px] md:h-auto pb-8 md:pb-32 md:py-32`}>
                <div ref={content} className="flex flex-col justify-center w-full md:w-1/2 space-y-4">
                    <Heading as="h2" leading="tight" transform="uppercase" content="Need a Silent Creative Partner?" color="white" className="text-left"></Heading>
                    <Text color="white" content="Silent yet powerful, our consulting services redefine collaboration. Operating in the shadows, we offer fresh perspectives, strategic solutions, and innovative ideas to propel your business forward."/>
                    <div>
                        <Button label="Book Now" theme_color="primary" href={data.pages.consulting.url} />
                    </div>
                </div>
            </Container>
        </Section>
    );
}