'use client';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useRef } from "react";
import { Heading, StylizedHeading } from "@/components/core/heading";
import Text from "@/components/core/text";
import Container from "@/components/core/container";
import Section from "@/components/core/section";
import { AssetImage } from "@/models/asset";
import { NonProfit } from "@/models/non_profits";
import { useThemeColors } from "@/lib/colors";
import Image from "next/image";
import Connect from "../connect";
import Supports from "../supports";
import { useGSAP } from "@gsap/react";
import { getAssetUrl } from "@/lib/cms";

gsap.registerPlugin(ScrollTrigger);

interface Data {
    collections: {
        non_profits: NonProfit[]
    },
    pages: {
        support_local: {
            id: string,
            url: string
        }
    }
}

interface Props {
    background_image: AssetImage | null,
    background_color: string,
    data: Data
}

export default function NonProfitFeatured({ background_color, data, ...props}: Props) {

    const container = useRef<HTMLDivElement>(null);
    const image = useRef<HTMLImageElement>(null);
    const content = useRef<HTMLDivElement>(null);

    const tl = useRef<gsap.core.Timeline>();

    const featured = data.collections.non_profits[0] ?? null;

    const colors = useThemeColors(background_color);

    useGSAP(() => {
        tl.current = gsap.timeline({
            scrollTrigger: {
                trigger: container.current,
                start: "top 50%",
            }
        });

        gsap.set(container.current, { autoAlpha: 0 })

        content.current?.children && gsap.set(content.current?.children, { autoAlpha: 0, y: 200 });

        tl.current
            .to(container.current, { autoAlpha: 1, duration: .2 });

        if (image.current) {
            const imageStartX = -100;
            gsap.set(image.current, { autoAlpha: 0, x: imageStartX })

            tl.current
                .to(image.current, { autoAlpha: 1, x: 0, duration: .5 }, "<");
        }

        content.current?.children && tl.current
            .to(content.current?.children, { autoAlpha: 1, y: 0, stagger: .2 });
    }, { scope: container, dependencies: [] });

    if (!featured) {
        return (<></>);
    }

    return (
        <Section ref={container} background_color={colors.background} className="py-24">
            <Container className={`flex flex-col sm:flex-row space-y-8 sm:space-y-0 items-center justify-center gap-8`}>
                <div ref={content} className="flex flex-col justify-center w-full md:w-1/2 space-y-4 sm:p-8 mt-8 sm:mt-0">
                    <StylizedHeading as="h2" size="3xl" color={colors.accent} content="Featured Non-Profit" />
                    <div>
                        <Heading as="h3" size="8xl" leading="tight" transform="uppercase" content={featured.title} color={colors.text}></Heading>
                        <Supports non_profit={featured} colors={colors} />
                    </div>
                    <Text color={colors.text} content={featured.content} />
                    <Connect non_profit={featured} colors={colors} />
                </div>
                <div className={`image-container flex items-center w-full sm:w-1/2 order-first sm:order-none`}>
                    <div className="relative z-50 pt-[100%] w-full">
                    {featured.image &&
                        <Image
                        ref={image}
                        className="rounded-xl max-w-full max-h-full w-full h-full absolute top-0 left-0"
                        src={getAssetUrl(featured.image)}
                        width={featured.image.width}
                        height={featured.image.height}
                        alt={featured.image.alt ?? `Image for ${featured.title}`}
                        style={{ 
                            objectFit: "cover",
                            objectPosition: featured.image.focus_css,
                        }}
                        />
                    }
                    </div>
                </div>
            </Container>
        </Section>
    );
}