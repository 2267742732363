'use client';

import { ReactNode } from "react";

export interface Props {
    id: string,
    selected: boolean,
    className?: string,
    children?: ReactNode,
}

export default function Tab(props: Props) {

    return (
        <div tabIndex={0} role="tabpanel" id={props.id} aria-labelledby={`${props.id}-tab`} className={props.className} hidden={!props.selected}>
            {props.selected && props.children}
        </div>
    )

};