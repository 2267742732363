'use client';

import { Heading } from "../../../components/core/heading";
import Text from "../../../components/core/text";

import { ThemeColorSet, useThemeColors } from "@/lib/colors";
import { NonProfit } from "@/models/non_profits";
import Supports from "../supports";
import Connect from "../connect";
import { getAssetUrl } from "@/lib/cms";
import Image from "next/image";
import "./../style.css";

interface Props {
    number: number,
    non_profit: NonProfit,
    colors: ThemeColorSet
}

export default function Card({ colors, ...props}: Props) {

    const accentColors = useThemeColors(colors.accent);

    return(
        <div className={`nonprofit-card relative w-full py-16 px-8 rounded-lg overflow-hidden bg-${colors.accent}`}>
            <div className="relative z-[2] flex flex-col w-full h-full">
                <div className="space-y-4 flex-col md:max-w-lg grow relative">
                    <Heading size="8xl" weight="bold" color={colors.accent_text}>{props.number < 10 ? '0' + props.number : props.number}</Heading>
                    <div className="space-y-1">
                        <Heading as="h3" size="6xl" weight="bold" color={colors.accent_text}>{props.non_profit.title}</Heading>
                        <Supports non_profit={props.non_profit} colors={accentColors} />
                    </div>
                    <Text content={props.non_profit.content} color={colors.accent_text} />

                </div>
                <div className="pt-4">
                    <Connect non_profit={props.non_profit} colors={accentColors} />
                </div>
            </div>
            <div className="absolute top-0 left-0 w-full h-full">
                <div className="absolute top-0 left-0 bg-gradient-to-t from-primary z-[1] w-full h-1/3"></div>
                <div className="absolute top-0 left-0 w-full h-1/3 overflow-hidden">
                    <Image src={getAssetUrl(props.non_profit.image)} width={props.non_profit.image?.width} height={props.non_profit.image?.height} alt={props.non_profit.image.alt} style={{
                        opacity: 2,
                        width: '100%',
                        height: '100%',
                        maxWidth: 'none',
                        objectFit: "cover",
                        objectPosition: props.non_profit.image?.focus_css,
                        mixBlendMode: "normal"
                    }} />
                </div>
            </div>
        </div>
    )
}