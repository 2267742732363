import { gsap } from "gsap";
import { createRef, useCallback, useEffect, useRef } from "react";
import { NavigationState, useNavigation } from "../provider";
import { useCursorEffects } from "@/modules/cursor/useCursorEffects";
import { useGSAP } from "@gsap/react";

export default function MenuTrigger() {
    const menu = createRef<HTMLButtonElement>();
    const background = createRef<HTMLDivElement>();
    const inner = createRef<HTMLDivElement>();

    const gsapContext = useRef<gsap.Context>();
    const tl = useRef<gsap.core.Timeline>();

    const { visible, toggle } = useNavigation();

    const onCursorEnter = useCallback(() => {
        if ([NavigationState.OPENED, NavigationState.OPENING].includes(visible)) {
            return { text: "Menu", color: "gray", textColor: "gray" };
        } else {
            return { text: "Menu", color: "primary", textColor: "primary" };
        }
    }, [visible])

    const onCursorLeave = () => {
        if ([NavigationState.OPENED, NavigationState.OPENING].includes(visible)) {
            return { color: "gray" };
        } else {
            return {};
        }
    }

    //useCursorEffects(menu, onCursorEnter, onCursorLeave)

    useGSAP((context) => {
        gsapContext.current = context;

        const duration = 0.4;

        tl.current = gsap.timeline({});

        context.add("open", () => {
            tl.current?.to(inner.current!.children[0], { top: 13, duration: duration, ease: "power3.out" })
                .to(inner.current!.children[2], { top: 13, duration: duration, ease: "power3.out" }, "<")
                .set(inner.current!.children[1], { autoAlpha: 0 })
                .to(background.current, { scale: 1, duration: 0.2, ease: "back.out" })
                .to(inner.current!.children, { backgroundColor: "#ffffff", duration: 0.2 }, "<")
                .to(inner.current!.children[0], { rotation: 45, duration: duration, ease: "power3.out" })
                .to(inner.current!.children[2], { rotation: "315_short", duration: duration, ease: "power3.out" }, "<");
        });

        context.add("close", () => {
            tl.current?.to(inner.current!.children[0], { rotation: 0, duration: duration, ease: "power3.out" })
                .to(inner.current!.children[2], { rotation: "0_short", duration: duration, ease: "power3.out" }, "<")
                .set(inner.current!.children[1], { autoAlpha: 1 })
                .to(background.current, { scale: 0, duration: 0.4, ease: "back.in" })
                .to(inner.current!.children, { backgroundColor: "#3e3e3d", duration: 0.2 }, "<")
                .to(inner.current!.children[0], { top: 0, duration: duration, ease: "power3.out" })
                .to(inner.current!.children[2], { top: 20, duration: duration, ease: "power3.out" }, "<");
        });

        //context.close();
    }, { scope: menu, dependencies: [inner] });

    useEffect(() => {
        if (visible == NavigationState.OPENING) {
            gsapContext.current && gsapContext.current.open();
        } else if (visible == NavigationState.CLOSING) {
            gsapContext.current && gsapContext.current.close();
        }
    }, [visible]);

    return (
        <button
            id="menu-toggle"
            ref={menu}
            aria-label="Open Menu"
            aria-expanded={visible == NavigationState.OPENED}
            aria-controls="menu"
            onClick={() => toggle()}>
            <div ref={inner} className="inner" aria-hidden={true}>
                <hr />
                <hr />
                <hr />
            </div>
            <div ref={background} className="background bg-primary"></div>
        </button>
    )
}
